import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TextField } from '@rmwc/textfield';

import sharedCss from 'src/styles/shared-ui/styles.module.scss';

FormikTextField.propTypes = {
  label: PropTypes.string.isRequired,
  formikName: PropTypes.string.isRequired,
  formikBag: PropTypes.any.isRequired,
  // USAGE: onBlurSetValue={value => value.toLowerCase()}
  // Provide a function that returns the next value.
  onBlurSetValue: PropTypes.func,
  hintText: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  getFormikValue: PropTypes.func,
};

export default function FormikTextField({
  label,
  formikName,
  formikBag,
  required,
  onBlurSetValue,
  hintText,
  className,
  getFormikValue = obj => obj[formikName],
  ...otherProps
}) {
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = formikBag;

  const value =
    getFormikValue(values) !== undefined ? getFormikValue(values) : '';
  const hasError = getFormikValue(touched) && getFormikValue(errors);
  const [labelText, setLabelText] = useState(label);

  useEffect(() => {
    setLabelText(`${label}${required ? '*' : ' '}`);
  }, [required, label, labelText]);

  return (
    <TextField
      key={labelText}
      label={labelText}
      name={formikName}
      outlined
      value={value}
      onChange={handleChange}
      onBlur={(...args) => {
        if (onBlurSetValue) {
          const nextValue = onBlurSetValue(value);

          setFieldTouched(formikName, true);
          setFieldValue(formikName, nextValue);
        } else {
          handleBlur(...args);
        }
      }}
      className={classnames(sharedCss.wide, className)}
      invalid={hasError}
      helpText={
        hasError
          ? {
              persistent: true,
              validationMsg: true,
              children: (
                <span>
                  {getFormikValue(errors).message || getFormikValue(errors)}
                </span>
              ),
            }
          : hintText
          ? {
              persistent: true,
              validationMsg: false,
              children: <span>{hintText}</span>,
            }
          : null
      }
      {...otherProps}
    />
  );
}
