import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { SnackbarQueue } from '@rmwc/snackbar';
import { Grid } from '@rmwc/grid';
// eslint-disable-next-line max-len
import queue from 'helpers/snackbarQueue';
// eslint-disable-next-line max-len
import Dashboard from '../modules/main/dashboard';
import Settings from '../modules/settings/settings';
import Login from '../modules/main/login';
import Registration from '../modules/main/register';
import TextCenter from '../modules/main/textCenter';
import ClientList from '../modules/clientList/ClientList';
import TenantCreation from '../modules/administration/TenantCreation';
import Templates from '../modules/templates/Templates';
import GroupTexting from '../modules/main/groupTexting';
// eslint-disable-next-line max-len
import IndividualConversation from 'modules/individualConversation/individualConversation';
import Administration from 'modules/administration/Administration';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { clearRedirectToLogin } from 'store/slices/userSlice';
import Echo from 'laravel-echo';
import config from 'src/helpers/config';
import { Helmet } from 'react-helmet';
import favicon from './favicon.png';
import faviconNotification from './faviconNotification.png';
import Notifier from 'react-desktop-notification';
import SupportModule from 'src/components/SupportModule';
import { isMobile } from 'src/helpers/utils.js';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isTextCenter = location.pathname === '/textCenter';
  const token = useSelector(state => state.user.token);
  const user = useSelector(state => state.user.user);
  const tabID = sessionStorage.tabID;
  const generalchannelName = user ? 'App.Models.User.' + user.id : undefined;
  const broadcastChannelName = user
    ? 'App.Models.User.' + user.id + '_' + tabID
    : undefined;
  const [newNotifications, setNewNotifications] = useState(false);
  const [echo, setEcho] = useState();
  const [echoDesktop, setEchoDesktop] = useState();
  //const notPerm = Notification.permission;

  window.Pusher = require('pusher-js');
  /*
  navigator.serviceWorker.ready.then(function(registration) {
    registration.showNotification('Vibration Sample', {
      body: 'Buzz! Buzz!',
      icon: '../images/touch/chrome-touch-icon-192x192.png',
      vibrate: [200, 100, 200, 100, 200, 100, 200],
      tag: 'vibration-sample',
    });
  });
*/
  useEffect(() => {
    if (newNotifications) {
      setNewNotifications(false);
    }
    // eslint-disable-next-line
  }, [isTextCenter]);

  useEffect(() => {
    if (user && !echo) {
      const e = getChannels();
      e?.private(generalchannelName).notification(() => {
        setNewNotifications(true);
        displayNotification();
      });
      setEcho(e);
      getNotificationPermissionIfNotGranted();
    }

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (user && !echoDesktop && !isMobile) {
      const e = getChannels();
      e?.private(broadcastChannelName).notification(textId => {
        Notifier.start(
          'HumaniSMS',
          'You have a new text!',
          'http://' + window.location.host + '/#/textCenter',
          favicon,
          textId.id
        );
      });
      setEchoDesktop(e);
    }
    // eslint-disable-next-line
  }, []);

  const history = useHistory();
  const redirectToLogin = useSelector(state => state.user.redirectToLogin);
  const invalidToken = useSelector(state => state.user.invalidToken);
  const pubReg = false;

  useEffect(() => {
    if (redirectToLogin) {
      history.push('/login');
      dispatch(clearRedirectToLogin());
    }
    // eslint-disable-next-line
  }, [redirectToLogin]);

  // eslint-disable-next-line
  const pubURL = window.location.hash;
  const isAddTenant = pubURL === '/#/addTenant';

  return (
    <React.Fragment>
      <link rel="manifest" href="/manifest.json" />
      <Helmet>
        <meta charSet="utf-8" />
        {newNotifications ? (
          <title>* HumaniSMS</title>
        ) : (
          <title>HumaniSMS</title>
        )}
        <link
          rel="icon"
          href={newNotifications ? faviconNotification : favicon}
          type="image/png"
        />
      </Helmet>
      <SnackbarQueue messages={queue.messages} />
      <Grid>
        <Switch>
          <Route exact path="/login" component={Login} onEnter={isLoggedIn} />
          <Route exact path="/addTenant" component={TenantCreation} />
          {pubReg && <Route exact path="/register" component={Registration} />}
          {!validAuth() && !isAddTenant ? (
            <Redirect to="/login" />
          ) : (
            <>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/administration" component={Administration} />
              <Route exact path="/clientList" component={ClientList} />
              <Route
                exact
                path="/individualConversation"
                component={IndividualConversation}
              />
              <Route exact path="/textCenter" component={TextCenter} />
              <Route exact path="/groupTexting" component={GroupTexting} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/templates" component={Templates} />
              <Route render={() => <Redirect to="/textCenter" />} />
            </>
          )}
        </Switch>
      </Grid>
      {validAuth() && <SupportModule />}
    </React.Fragment>
  );

  function validAuth() {
    if (!user || isExpired() || invalidToken) {
      return false;
    }
    return true;
  }

  function isLoggedIn() {
    if (user && !isExpired() && !invalidToken) {
      return { pathname: 'dashboard' };
    }
    return false;
  }

  function isExpired() {
    if (!user) {
      return false;
    }
    const expiry = moment(user.exp, 'X');
    const now = moment();

    return now.isAfter(expiry);
  }

  function getChannels() {
    return new Echo({
      broadcaster: 'pusher',
      key: config.smsMgmtEchoKey,
      cluster: config.smsMgmtEchoCluster,
      forceTLS: false,
      wsHost: config.smsMgmtEchoHost,
      wsPort: 6001,
      authEndpoint: config.smsManagementBackendUrl + '/broadcasting/auth',
      // As I'm using JWT tokens, I need to manually set up the headers.
      auth: {
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
        },
      },
      enabledTransports: ['ws', 'wss'],
    });
  }
}

function displayNotification() {
  if (Notification.permission == 'granted') {
    navigator.serviceWorker.getRegistration().then(function(reg) {
      let options = {
        body: 'You have a new text message!',
        icon: faviconNotification,
        vibrate: [100, 50, 100],
        data: {
          dateOfArrival: Date.now(),
          primaryKey: 1,
        },
      };
      reg.showNotification('New Text!', options);
    });
  } else {
    Notification.requestPermission(function(status) {
      console.log('Notification permission status:', status);
    });
  }
}
function getNotificationPermissionIfNotGranted() {
  if (
    Notification.permission !== 'denied' &&
    Notification.permission !== 'granted'
  ) {
    Notification.requestPermission(function(status) {
      console.log('Notification permission status:', status);
    });
  }
}

export default App;
