import { all, put, takeLatest } from 'redux-saga/effects';
import * as api from 'src/api';
import snackbarQueue from 'helpers/snackbarQueue';
import { callWithToken } from 'helpers/sagaHelpers';
import {
  getClients,
  saveClient,
  deleteClient,
  setSliceClient,
  setClients,
  setSliceClientToken,
} from 'store/slices/clientsSlice';

function* getClientsSaga() {
  try {
    const clients = yield callWithToken(api.getClients);
    yield put(setClients({ clients: clients }));
  } catch (e) {
    snackbarQueue.notify({
      body: 'There was an error getting your contacts...',
      dismissesOnAction: true,
    });
  }
}
function* setSliceClientSaga(action) {
  const { client } = action.payload;
  try {
    const token = yield callWithToken(
      api.getIndividualClientConvToken,
      client.id
    );
    yield put(setSliceClientToken({ clientToken: token.token }));
  } catch (e) {
    snackbarQueue.notify({
      body: 'There was an error retrieving your conversation...',
      dismissesOnAction: true,
    });
  }
}

function* saveClientSaga(action) {
  const { client } = action.payload;

  try {
    const clients = yield callWithToken(api.saveClient, client);
    yield put(setClients({ clients: clients }));
  } catch (e) {
    snackbarQueue.notify({
      body: 'There was an error saving your contact...',
      dismissesOnAction: true,
    });
  }
}
function* deleteClientSaga(action) {
  const { client } = action.payload;

  try {
    const clients = yield callWithToken(api.deleteClient, client);
    if (!clients.isValid) {
      throw new Error('error');
    }

    yield put(setClients({ clients: clients }));

    snackbarQueue.notify({
      body: 'Contact has been removed...',
      dismissesOnAction: true,
    });
  } catch (e) {
    snackbarQueue.notify({
      body:
        /* eslint-disable */
        'There was an error deleting your contact, if this continues please contact support...',
      dismissesOnAction: true,
    });
  }
}

export default function*() {
  yield all([
    yield takeLatest(getClients.type, getClientsSaga),
    yield takeLatest(saveClient.type, saveClientSaga),
    yield takeLatest(deleteClient.type, deleteClientSaga),
    yield takeLatest(setSliceClient.type, setSliceClientSaga),
  ]);
}
