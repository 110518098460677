import { all, put, takeLatest } from 'redux-saga/effects';
import * as api from 'src/api';
import snackbarQueue from 'helpers/snackbarQueue';
import { callWithToken } from 'helpers/sagaHelpers';
import {
  getTemplates,
  setTemplates,
  saveTemplate,
} from 'store/slices/templatesSlice';

function* getTemplatesSaga() {
  try {
    const response = yield callWithToken(api.getTemplates);
    yield put(setTemplates({ templates: response.templates }));
  } catch (e) {
    console.log(e);
    snackbarQueue.notify({
      body: 'There was an error getting your templates...',
      dismissesOnAction: true,
    });
  }
}
function* saveTemplateSaga(action) {
  const { template } = action.payload;

  try {
    // eslint-disable-next-line
    const response = yield callWithToken(api.saveTemplate, template);

    if (!response.isValid) {
      snackbarQueue.notify({
        body: response.error,
        dismissesOnAction: true,
      });
    } else {
      yield put(setTemplates({ templates: response.templates }));
      snackbarQueue.notify({
        body: 'Template has successfully been saved...',
        dismissesOnAction: true,
      });
    }
  } catch (e) {
    snackbarQueue.notify({
      body: 'There was an error saving your template...',
      dismissesOnAction: true,
    });
  }
}

export default function*() {
  yield all([
    yield takeLatest(getTemplates.type, getTemplatesSaga),
    yield takeLatest(saveTemplate.type, saveTemplateSaga),
  ]);
}
