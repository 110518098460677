import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from 'modules/navBar/navbar.js';
import config from 'helpers/config';
import { GridCell } from '@rmwc/grid';
import css from './textCenter.module.scss';
import { useHistory } from 'react-router';
import useDetectScreenSizeGranular, {
  screenSizeGranular,
} from 'hooks/useMedia';
import Section from '../../components/Section';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@rmwc/circular-progress';
import { logoutUser } from 'store/slices/userSlice';

export default function TextCenter() {
  const history = useHistory();
  const dispatch = useDispatch();
  const textCenterToken = useSelector(state => state.user.textCenterToken);
  const showGroupTextCenter = useSelector(
    state => state.textCenter.showGroupTextCenter
  );
  const directTo = useSelector(state => state.textCenter.directTo);
  const iframeRef = useRef(null);
  const [src, setSrc] = useState(
    config.smsCoreUrl + '/groupTexting?jwt=' + textCenterToken
  );
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const screenSize = useDetectScreenSizeGranular();
  const dMin = screenSizeGranular.desktopMin === screenSize;

  useEffect(() => {
    if (!showGroupTextCenter) {
      setSrc(undefined);
      setTimeout(() => {
        history.push('/' + directTo);
        if (directTo === 'login') {
          dispatch(logoutUser());
        }
      }, 100);
    }
    // eslint-disable-next-line
  }, [showGroupTextCenter]);

  return textCenterToken !== false ? (
    <>
      <NavBar />
      <GridCell span={dMin ? 9 : 10}>
        {!iframeLoaded && <CircularProgress size="large" />}
        <iframe
          id="textCenterIframe"
          className={css.textCenterIframe}
          onLoad={() => {
            setIframeLoaded(true);
          }}
          src={src}
          ref={iframeRef}
        />
      </GridCell>
    </>
  ) : (
    <>
      <NavBar />
      <Section
        name="Text Center"
        span={dMin ? 9 : 10}
        className={css.dashboard}
      >
        <Typography
          use="headline5"
          style={{ marginLeft: '15px', marginBottom: '25px' }}
        >
          Please update your settings or speak with your Administrator to move
          forward
        </Typography>
      </Section>
    </>
  );
}
