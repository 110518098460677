import React from 'react';
import { useSelector } from 'react-redux';
import css from '../individualConversation/individualConversation.module.scss';
import NavBar from 'modules/navBar/navbar.js';
import { GridCell } from '@rmwc/grid';
import config from 'helpers/config';
import useDetectScreenSizeGranular, {
  screenSizeGranular,
} from 'hooks/useMedia';

export default function IndividualConversation() {
  const token = useSelector(state => state.clients.clientToken);
  const screenSize = useDetectScreenSizeGranular();
  const dMin = screenSizeGranular.desktopMin === screenSize;

  return (
    <>
      <NavBar />
      <GridCell span={dMin ? 9 : 10}>
        {token && (
          <iframe
            src={config.smsCoreUrl + '/individualConversation?jwt=' + token}
            className={css.conversationIframe}
          />
        )}
      </GridCell>
    </>
  );
}
