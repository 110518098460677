import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@rmwc/typography';

import css from './errorBoundary.module.scss';

// Use something like: throw new Error('SHOW:display')
// to display "display" to the user.
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false, errorMessage: undefined };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true, errorMessage: error.message };
  }

  render() {
    if (this.state.hasError || this.props.hasReduxError) {
      const reportedErrorMessage =
        this.state.errorMessage || this.props.reduxErrorMessage;

      const errorMessage = (reportedErrorMessage || '').includes('SHOW')
        ? reportedErrorMessage.slice(5)
        : 'Unexpected error occurred.';

      return (
        <div className={css.error}>
          <Typography use="h5">{errorMessage}</Typography>
          <Typography use="caption" tag="div">
            If the problem persists, please contact support.
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  hasReduxError: PropTypes.bool.isRequired,
  reduxErrorMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default connect(state => {
  return {
    hasReduxError: state.error.hasError,
    reduxErrorMessage: state.error.errorMessage,
  };
})(ErrorBoundary);
