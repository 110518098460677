/* eslint-disable valid-jsdoc */
import { call, select, put } from 'redux-saga/effects';

/**
 * Call a function with token + args. This is commonly used when calling an API.
 *
 * Example:
 *
 * ```
 *  const result = yield callWithToken(api.getEmployees, includeActive);
 *  ```
 *
 *  This is similar to what you'd normally have to do:
 *  ```
 *    const token = yield select(state => state.user.token);
 *    const result = yield call(api.getEmployees, token, includeActive);
 * ```
 * @param fn The function to call. Usually something like, `api.getEmployees`.
 * @param args The arguments to pass to `fn` after token is included
 * @returns {Generator<*, void, *>}
 */
export function* callWithToken(fn, ...args) {
  const token = yield select(state => state.user.token);
  return yield call(fn, token, ...args);
}

/**
 * Get data from an API. If successful, dispatch the success action with the
 * data (under propertyName). Otherwise, dispatch the failure action.
 * @param apiFn API function to call
 * @param apiArgs {Array} Extra args to pass to the API (after token)
 * @param getSuccessAction {Function} get the success action
 * @param failureAction
 * @returns {Function}
 */
export function getFromAPI({
  apiFn,
  apiArgs = [],
  getSuccessAction,
  failureAction,
  fatalErrorOnFailure = false,
}) {
  return function* saga() {
    try {
      const results = yield callWithToken(apiFn, ...apiArgs);
      yield put(getSuccessAction(results));
    } catch (e) {
      if (failureAction) {
        yield put(failureAction());
      }

      if (fatalErrorOnFailure) {
        throw new Error('API fetch failed: ' + e.message);
      }
    }
  };
}
