import React from 'react';
import { Button } from '@rmwc/button';
import classnames from 'classnames';
import css from './login.module.scss';
import { Grid, GridCell } from '@rmwc/grid';
import Section from '../../components/Section';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import { TextField } from '@rmwc/textfield';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { registerUser } from 'store/slices/userSlice';
import { useDispatch } from 'react-redux';

export default function Registration() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      username: '',
      email: '',
      password: '',
    },
    onSubmit: valuesForSave => {
      dispatch(registerUser({ dispatch, registration: valuesForSave }));
    },
  });

  return (
    <>
      <GridCell span={4} />
      <GridCell span={4}>
        <Section name="Registration" className={css.registrationForm}>
          <Grid>
            <GridCell span={12}>
              <TextField
                outlined
                label="Username"
                name="username"
                value={values.username}
                onChange={evt => {
                  setFieldValue('username', evt.target.value);
                }}
                className={classnames(sharedCss.wide, sharedCss.marginTop)}
              />
            </GridCell>
            <GridCell span={12}>
              <TextField
                outlined
                label="Email"
                name="email"
                onChange={evt => {
                  setFieldValue('email', evt.target.value);
                }}
                className={classnames(sharedCss.wide, sharedCss.marginTop)}
              />
            </GridCell>
            <GridCell span={6}>
              <TextField
                outlined
                label="Password"
                name="password"
                type="password"
                onChange={evt => {
                  setFieldValue('password', evt.target.value);
                }}
                className={classnames(sharedCss.wide, sharedCss.marginTop)}
              />
            </GridCell>
            <GridCell span={6}>
              <TextField
                outlined
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                className={classnames(sharedCss.wide, sharedCss.marginTop)}
              />
            </GridCell>
          </Grid>
          <hr className={sharedCss.marginTop} />
          <Button
            outlined
            raised
            theme={['secondaryBg', 'onSecondary']}
            onClick={() => {
              handleSubmit();
            }}
            label="Register"
          />
          <Button
            outlined
            theme={['secondaryBg', 'onSecondary']}
            className={classnames(css.floatRight)}
            label="Cancel"
            onClick={() => {
              history.push('/login');
            }}
          />
        </Section>
      </GridCell>
      <GridCell span={4} />
    </>
  );
}
