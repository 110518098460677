import { all, takeLatest } from 'redux-saga/effects';
import * as api from 'src/api';
import snackbarQueue from 'helpers/snackbarQueue';
import { callWithToken } from 'helpers/sagaHelpers';
import { saveTenant } from 'store/slices/tenantsSlice';

function* saveTenantSaga(action) {
  const { tenant } = action.payload;

  try {
    // eslint-disable-next-line
    const newTenant = yield callWithToken(api.saveTenant, tenant);

    if (!newTenant.isValid) {
      snackbarQueue.notify({
        body: newTenant.error,
        dismissesOnAction: true,
      });
    } else {
      snackbarQueue.notify({
        body: 'Account has successfully been created...',
        dismissesOnAction: true,
      });
    }
  } catch (e) {
    snackbarQueue.notify({
      body: 'There was an error saving your account...',
      dismissesOnAction: true,
    });
  }
}

export default function*() {
  yield all([yield takeLatest(saveTenant.type, saveTenantSaga)]);
}
