import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Elevation } from '@rmwc/elevation';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import { Grid, GridCell } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';

import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import css from './section.module.scss';
import { useDispatch } from 'react-redux';
import logoImg from './logo.jpg';
import { hideNavBar } from 'src/store/slices/settingsSlice';
import { IsMobile } from 'hooks/useMedia';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
} from '@rmwc/top-app-bar';

Section.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.bool,
  onEdit: PropTypes.func,
  children: PropTypes.node,
  // These define grid sizing for the whole section.
  // If defined, the default behavior (full width), will
  // not be applied.
  span: PropTypes.number,
  desktop: PropTypes.number,
  tablet: PropTypes.number,
  phone: PropTypes.number,
  // applied to elevation
  className: PropTypes.string,
  bankroll: PropTypes.any,
  headerButton: PropTypes.string,
  headerButtonFunc: PropTypes.func,
  searchFuncValue: PropTypes.string,
  searchFunc: PropTypes.func,
  showNavBurger: PropTypes.bool,
  isNav: PropTypes.bool,
};

function Section({
  name,
  logo,
  onEdit,
  children,
  span,
  desktop,
  tablet,
  phone,
  className,
  searchFuncValue,
  searchFunc,
  headerButton,
  headerButtonFunc,
  showNavBurger = false,
}) {
  const dispatch = useDispatch();
  const anySizing =
    span !== undefined ||
    desktop !== undefined ||
    tablet !== undefined ||
    phone !== undefined;

  const isMobile = IsMobile();

  return (
    <GridCell
      span={!anySizing ? 12 : span}
      desktop={desktop}
      tablet={tablet}
      phone={phone}
      style={{ padding: '0px' }}
    >
      <Elevation
        z={1}
        className={classnames(
          css.relative,
          sharedCss.backgroundSurface,
          className
        )}
      >
        {showNavBurger && isMobile && (
          <TopAppBar style={{ backgroundColor: 'white' }}>
            <TopAppBarRow>
              <TopAppBarSection>
                <TopAppBarNavigationIcon
                  icon="menu"
                  onClick={() => {
                    dispatch(hideNavBar());
                  }}
                />
              </TopAppBarSection>
            </TopAppBarRow>
          </TopAppBar>
        )}
        <Grid className={logo ? css.smallPadding : undefined}>
          {name && (
            <>
              <GridCell desktop={10} tablet={5} phone={5}>
                <Typography use="headline6" tag="h2">
                  {logo ? <img className={css.logo} src={logoImg} /> : name}

                  {headerButton && headerButtonFunc && (
                    <Button
                      outlined
                      raised
                      theme={['secondaryBg', 'onSecondary']}
                      onClick={headerButtonFunc}
                      label={headerButton}
                      style={{ marginLeft: '25px' }}
                    />
                  )}
                </Typography>
              </GridCell>
            </>
          )}
          {searchFunc && (
            <GridCell desktop={2} tablet={3} phone={3}>
              <TextField
                outlined
                label="Search"
                className={css.search}
                value={searchFuncValue || ''}
                onChange={e =>
                  dispatch(
                    searchFunc({
                      allColumnsSearchQuery: e.target.value,
                    })
                  )
                }
              />
            </GridCell>
          )}
        </Grid>
        <hr />
        {onEdit && (
          <Icon
            icon={{ icon: 'edit', size: 'small' }}
            className={classnames(css.icon, sharedCss.colorPrimary)}
            onClick={onEdit}
            aria-label={`Edit ${name}`}
          />
        )}
        {children}
      </Elevation>
    </GridCell>
  );
}

export default Section;
