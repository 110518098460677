export const permissionLevels = {
  user: '0',
  admin: '1',
  superAdmin: '2',
};

export const permissionLevelsOptions2 = [
  { label: 'User', value: 0 },
  { label: 'Admin', value: 1 },
  { label: 'Super Admin', value: 2 },
];
export const permissionLevelsOptions1 = [
  { label: 'User', value: 0 },
  { label: 'Admin', value: 1 },
];
