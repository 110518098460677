export function transformUsers(users) {
  return users.map(transformUser);
}

export function transformUser(user) {
  if (!user) {
    return user;
  }
  return {
    ...user,
    username: user.name || '',
  };
}
