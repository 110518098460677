import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DataTable,
  DataTableBody,
  DataTableContent,
  DataTableCell,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow,
} from '@rmwc/data-table';
import { getUsers } from 'store/slices/usersSlice';
import { filterByText } from 'helpers/filter';
import { compose } from 'helpers/utils';
import { Icon } from '@rmwc/icon';
import PropTypes from 'prop-types';
import DeleteUserDialog from 'modules/administration/DeleteUserDialog';
import { formatPhoneNumber } from 'src/helpers/utils';

UserList.propTypes = {
  editUser: PropTypes.object,
  setUser: PropTypes.func,
};

export default function UserList({ editUser, setUser }) {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { allColumnsSearchQuery } = useSelector(mapState, shallowEqual);
  const [deleteOpen, setDeleteOpen] = useState();
  const { userList } = useSelector(getUsersFromState, shallowEqual);
  const user = useSelector(state => state.user.user);
  const isCloudBased = user.tenantId !== undefined;

  function onClose() {
    setDeleteOpen(false);
  }

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  function userRow(userItem) {
    return (
      <DataTableRow key={userItem.id} selected={userItem.id === editUser?.id}>
        <DataTableCell>
          {user.permissionLevel >= parseInt(userItem.permissionLevel, 10) && (
            <>
              <Icon
                icon={{ icon: 'edit', size: 'xsmall' }}
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => {
                  setUser(userItem);
                }}
              />
              <Icon
                icon={{ icon: 'delete', size: 'xsmall' }}
                style={{ color: 'red', cursor: 'pointer', marginLeft: '10px' }}
                onClick={() => {
                  setUser(userItem);
                  setDeleteOpen(true);
                }}
              />
            </>
          )}
        </DataTableCell>
        <DataTableCell>{userItem.firstName}</DataTableCell>
        <DataTableCell>{userItem.lastName}</DataTableCell>
        <DataTableCell>{formatPhoneNumber(userItem.phone)} </DataTableCell>
        <DataTableCell>{userItem.email}</DataTableCell>
      </DataTableRow>
    );
  }

  function getUserList() {
    return userList && !isCloudBased
      ? userList.map(userItem => userRow(userItem))
      : userList
          .filter(x => x.tenantId === user.tenantId)
          .map(userItem => userRow(userItem));
  }

  return (
    <>
      <DeleteUserDialog
        user={editUser}
        open={deleteOpen}
        onClose={onClose}
        setOpen={setDeleteOpen}
      />
      <DataTable style={{ height: '75vh', overflowY: 'scroll', width: '100%' }}>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell> </DataTableHeadCell>
              <DataTableHeadCell>First Name</DataTableHeadCell>
              <DataTableHeadCell>Last Name</DataTableHeadCell>
              <DataTableHeadCell>Phone</DataTableHeadCell>
              <DataTableHeadCell>Email</DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>{getUserList()}</DataTableBody>
        </DataTableContent>
      </DataTable>
    </>
  );
}

function mapState(state) {
  return {
    allColumnsSearchQuery: state.users.allColumnsSearchQuery,
  };
}

function getUsersFromState(state) {
  const { allColumnsSearchQuery } = state.users;
  const users = state.users?.users?.slice();
  if (!users) {
    return [];
  }

  return {
    userList: compose(
      filterByText(allColumnsSearchQuery, [
        'fullName',
        'lastName',
        'phone',
        'email',
      ])
    )(users),
  };
}
