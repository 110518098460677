export function compose(...fns) {
  return x => fns.reduceRight((v, f) => f(v), x);
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function sortBy(propertyAccessor, direction) {
  return (a, b) => {
    const aProp = propertyAccessor(a);
    const bProp = propertyAccessor(b);

    if (typeof aProp === 'string') {
      return aProp.localeCompare(bProp) * direction;
    }

    return (aProp - bProp) * direction;
  };
}
export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? '' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}
export function RemoveTabFromStorage() {
  const tabID = sessionStorage.tabID;
  if (tabID) {
    console.log('Removing from current Tabs');
    let tabs = JSON.parse(localStorage.getItem('currentTabs')) || [];
    if (Array.isArray(tabs)) {
      const index = tabs.indexOf(tabID);
      if (index !== -1) {
        tabs = tabs.splice(index, 1);
      }
      //remove current tab from list of tabs
      localStorage.setItem('currentTabs', tabs);
      const listenerTab = localStorage.getItem('listenerTab');
      if (tabID === listenerTab) {
        console.log('This is the listener tab, creating a new listener');
        //if we are closing the listener tab, assign a new listener tab
        localStorage.setItem('listenerTab', tabs[0]);
      }
    } else {
      localStorage.removeItem('currentTabs');
    }
  }
}
export function isMobile() {
  // eslint-disable-next-line
  if (
    // eslint-disable-next-line
    /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  }
  return false;
}
