import React, { useState } from 'react';
import css from 'src/components/supportmodal.module.scss';
import { Fab } from '@rmwc/fab';
import SupportModal from 'src/components/SupportModal';
import { IsMobile } from 'hooks/useMedia';

export default function SupportModule() {
  const [open, setOpen] = useState(false);

  return !IsMobile() ? (
    <>
      <Fab
        icon="contact_support"
        onClick={() => {
          setOpen(!open);
        }}
        className={css.supportFab}
      />
      <SupportModal open={open} setOpen={setOpen} />
    </>
  ) : null;
}
