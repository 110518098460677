import React from 'react';
import { GridCell } from '@rmwc/grid';
import Section from '../../components/Section';
import css from '../main/login.module.scss';
import sharedCss from '../../styles/shared-ui/styles.module.scss';
import { Button } from '@rmwc/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveTenant } from 'store/slices/tenantsSlice';
import { useDispatch } from 'react-redux';
import FormikTextField from '../../components/FormikTextField';

export default function TenantCreation() {
  const dispatch = useDispatch();

  const formikBag = useFormik({
    initialValues: {
      company: '',
      address: '',
      phone: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      company: Yup.string().required('Please include a company'),
      address: Yup.string().required('Please include a address'),
      password: Yup.string().required('Please include a password'),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
      phone: Yup.string().required('Please include a phone'),
      firstName: Yup.string().required('Please include a first name'),
      lastName: Yup.string().required('Please include a last name'),
      email: Yup.string().required('Please include an email'),
    }),
    onSubmit: valuesForSave => {
      dispatch(saveTenant({ tenant: valuesForSave }));
    },
  });

  const { handleSubmit } = formikBag;
  return (
    <>
      <GridCell span={3} />
      <GridCell span={5}>
        <Section name="Add Tenant" className={css.loginForm}>
          <>
            <FormikTextField
              label="Company"
              formikName="company"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
            <FormikTextField
              label="Address"
              formikName="address"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
            <FormikTextField
              label="Phone"
              formikName="phone"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
            <FormikTextField
              label="First Name"
              formikName="firstName"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
            <FormikTextField
              label="Last Name"
              formikName="lastName"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
            <FormikTextField
              label="Email"
              formikName="email"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
            <FormikTextField
              label="Password"
              formikName="password"
              formikBag={formikBag}
              required
              autoComplete="off"
              type="password"
              className={sharedCss.marginTop}
            />
            <FormikTextField
              label="Confirm Password"
              formikName="confirmPassword"
              formikBag={formikBag}
              required
              autoComplete="off"
              type="password"
              className={sharedCss.marginTop}
            />
            <hr className={sharedCss.marginTop} />
            <div>
              <Button
                type="submit"
                outlined
                raised
                theme={['secondaryBg', 'onSecondary']}
                onKeyUp
                onClick={() => {
                  handleSubmit();
                }}
                label="Add Tenant"
              />
            </div>
          </>
        </Section>
      </GridCell>
      <GridCell span={4} />
    </>
  );
}
