import config from 'helpers/config';
import { getErrorMessage } from 'helpers/apiHelpers';

async function getResponse(baseUrl, url, token) {
  return await fetch(`${baseUrl}${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

async function get(url, token) {
  const response = await getResponse(
    config.smsManagementBackendUrl,
    url,
    token
  );
  const result = response.clone();

  if (response.ok) {
    return await result.clone().json();
  }

  return getErrorMessage(response);
}

async function getCoreBackend(url, token) {
  const response = await getResponse(config.smsCoreBackendUrl, url, token);
  const result = response.clone();

  if (response.ok) {
    return await result.clone().json();
  }

  return getErrorMessage(response);
}

async function post(url, token, data, parseJSON = true) {
  const response = await fetch(`${config.smsManagementBackendUrl}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    return parseJSON ? await response.clone().json() : response;
  }

  return getErrorMessage(response);
}

async function postCoreBackend(url, token, data, parseJSON = true) {
  const response = await fetch(`${config.smsCoreBackendUrl}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    return parseJSON ? await response.clone().json() : response;
  }

  return getErrorMessage(response);
}

async function postFormData(url, token, data) {
  const res = await fetch(config.smsManagementBackendUrl + url, {
    method: 'POST',
    body: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await res.json();
}

// eslint-disable-next-line
async function _delete(url, token, data) {
  const response = await fetch(`${config.smsManagementBackendUrl}${url}`, {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  return await response.json();
}

export async function registerUser(token, data) {
  return post('/api/addUser', token, data);
}
export async function deleteUser(token, user) {
  return post('/api/deleteUser', token, user);
}
export async function submitSupportTicket(token, data) {
  return post('/api/submitSupportTicket', token, data);
}
export async function loginUser(data) {
  return post('/api/login', undefined, data);
}
export async function setSettings(token, data) {
  return post('/api/saveSettings', token, data);
}
export async function getSettings(token) {
  return get('/api/getSettings', token);
}
export async function getPublicRegistration(token) {
  return get('/api/getPublicRegistration', token);
}
export async function getClients(token) {
  return get('/api/getClients', token);
}
export async function saveClient(token, client) {
  return post('/api/saveClient', token, client);
}
export async function deleteClient(token, client) {
  return post('/api/deleteClient', token, client);
}
export async function saveTenant(token, tenant) {
  return post('/api/addTenant', token, tenant);
}
export async function getIndividualClientConvToken(token, clientId) {
  return get('/api/getClientIndConvToken/' + clientId, token);
}
export async function getUsers(token) {
  return get('/api/getUsers', token);
}
export async function getTemplates(token) {
  return getCoreBackend('/api/getTemplates', token);
}
export async function saveTemplate(token, template) {
  return postCoreBackend('/api/saveTemplate', token, template);
}
export async function checkNotifications(token, userId) {
  return getCoreBackend('/api/checkNotifications/' + userId, token);
}
export async function saveAvatar(token, avatar) {
  const avatarFile = new File([avatar], 'avatar.jpg');

  let fd = new FormData();
  fd.append('avatar', avatarFile, 'avatar.jpg');

  return postFormData('/api/saveAvatar', token, fd);
}
