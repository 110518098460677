import { createSlice } from '@reduxjs/toolkit';

const templatesSlice = createSlice({
  name: 'templates',
  initialState: {
    template: undefined,
    allColumnsSearchQuery: undefined,
    templates: [],
  },
  reducers: {
    getTemplates() {},
    setTemplates(state, action) {
      state.templates = action.payload.templates;
    },
    clearTemplates(state) {
      state.templates = [];
    },
    saveTemplate() {},
  },
});

export const {
  getTemplates,
  setTemplates,
  clearTemplates,
  saveTemplate,
} = templatesSlice.actions;

export default templatesSlice.reducer;
