import { all, put, takeLatest } from 'redux-saga/effects';
import * as api from 'src/api';
import snackbarQueue from 'helpers/snackbarQueue';
import { callWithToken } from 'helpers/sagaHelpers';
import { getUsers, setUsers } from 'store/slices/usersSlice';
import { transformUsers } from 'helpers/usersTransform';

function* getUsersSaga() {
  try {
    const users = yield callWithToken(api.getUsers);
    yield put(setUsers({ users: transformUsers(users.users) }));
  } catch (e) {
    console.log(e);
    snackbarQueue.notify({
      body: 'There was an error getting your clients...',
      dismissesOnAction: true,
    });
  }
}

export default function*() {
  yield all([yield takeLatest(getUsers.type, getUsersSaga)]);
}
