import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DataTable,
  DataTableBody,
  DataTableContent,
  DataTableCell,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow,
} from '@rmwc/data-table';
import { getTemplates } from 'store/slices/templatesSlice';
import { filterByText } from 'helpers/filter';
import { compose } from 'helpers/utils';
import { Icon } from '@rmwc/icon';
import PropTypes from 'prop-types';

TemplateList.propTypes = {
  setTemplate: PropTypes.func,
};

export default function TemplateList({ setTemplate }) {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { allColumnsSearchQuery } = useSelector(mapState, shallowEqual);
  const { templateList } = useSelector(getTemplatesFromState, shallowEqual);
  //const user = useSelector(state => state.user.user);

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  function templateRow(item) {
    return (
      <DataTableRow key={item.id}>
        <DataTableCell>
          <Icon
            icon={{ icon: 'edit', size: 'xsmall' }}
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              setTemplate(item);
            }}
          />
        </DataTableCell>
        <DataTableCell>{item.name}</DataTableCell>
        <DataTableCell>{item.description}</DataTableCell>
      </DataTableRow>
    );
  }

  return (
    <>
      <DataTable style={{ height: '75vh', overflowY: 'scroll', width: '100%' }}>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell> </DataTableHeadCell>
              <DataTableHeadCell>Template Name</DataTableHeadCell>
              <DataTableHeadCell>Description</DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {templateList.map(item => templateRow(item))}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
    </>
  );
}

function mapState(state) {
  return {
    allColumnsSearchQuery: state.templates.allColumnsSearchQuery,
  };
}

function getTemplatesFromState(state) {
  const { allColumnsSearchQuery } = state.templates;
  const templates = state.templates?.templates?.slice();
  if (!templates) {
    return [];
  }

  return {
    templateList: compose(filterByText(allColumnsSearchQuery, ['name']))(
      templates
    ),
  };
}
