import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    hasError: false,
    errorMessage: undefined,
  },
  reducers: {
    setError(state, action) {
      state.hasError = true;
      state.errorMessage = action.payload.error.message;
    },
  },
});

export const { setError } = errorSlice.actions;

export default errorSlice.reducer;
