import React from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { GridCell } from '@rmwc/grid';
import { List, ListDivider } from '@rmwc/list';
import Section from '../../components/Section';
import ProfileNavSection from './profileNavSection';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import css from './navbar.module.scss';
import { permissionLevels } from 'src/enums/roleEnums';
import { showNavBar } from 'src/store/slices/settingsSlice';
import {
  removeTextCenter,
  removeGroupTextCenter,
} from 'store/slices/textCenterSlice';
import useDetectScreenSizeGranular, {
  screenSizeGranular,
  IsMobile,
} from 'hooks/useMedia';
import NavItem from './NavItem';
import { logoutUser } from '../../store/slices/userSlice';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
} from '@rmwc/top-app-bar';
import { Elevation } from '@rmwc/elevation';

export default function NavBar() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const showNav = useSelector(state => state.settings.showNav);
  const location = useLocation();
  const path = location.pathname;
  const isMobile = IsMobile();
  const sectionClassNames = isMobile
    ? classnames(sharedCss.marginTop, css.profileBar)
    : css.profileBar;

  const showNavigation = !isMobile || (isMobile && showNav);

  const screenSize = useDetectScreenSizeGranular();
  const dMin = screenSizeGranular.desktopMin === screenSize;

  function headerButton() {
    if (path === '/textCenter') {
      dispatch(removeTextCenter({ directTo: 'login' }));
    }
    if (path === '/groupTexting') {
      dispatch(removeGroupTextCenter({ directTo: 'login' }));
    }
    if (path !== '/textCenter' && path !== '/groupTexting') {
      dispatch(logoutUser());
    }
  }

  function getInners() {
    return (
      <>
        <Section
          name="HumaniSMS"
          logo={true}
          className={css.navbar}
          desktop={2}
          tablet={12}
          phone={12}
          showNavBurger={true}
          className={sectionClassNames}
        >
          <List>
            <NavItem label="Contacts" newPath="clientList" />
            <NavItem
              label="TextCenter"
              newPath="textCenter"
              showTextCenterIframe={true}
            />
            <NavItem
              label="Group Texting"
              newPath="groupTexting"
              showTextCenterIframe={true}
            />
            <ListDivider />
            <NavItem label="Templates" newPath="templates" />
            <NavItem label="User Admin" newPath="administration" />
            {(user.permissionLevel === permissionLevels.superAdmin ||
              user.permissionLevel.toString() ===
                permissionLevels.superAdmin) && (
              <NavItem label="Settings" newPath="settings" />
            )}
            {isMobile && (
              <NavItem
                onClick={() => {
                  dispatch(logoutUser());
                }}
                label="Logout"
                newPath="login"
              />
            )}
          </List>
        </Section>
        {!isMobile && (
          <ProfileNavSection
            name="Profile"
            headerButton={headerButton}
            className={classnames(sharedCss.marginTop, css.profileBar)}
          />
        )}
      </>
    );
  }

  function mobileHidden() {
    return (
      <Elevation className={css.navbarMobileHidden} tablet={12} phone={12}>
        <TopAppBar style={{ backgroundColor: 'white' }}>
          <TopAppBarRow>
            <TopAppBarSection>
              <TopAppBarNavigationIcon
                icon="menu"
                onClick={() => {
                  dispatch(showNavBar());
                }}
              />
            </TopAppBarSection>
          </TopAppBarRow>
        </TopAppBar>
      </Elevation>
    );
  }

  return (
    <>
      {showNavigation && (
        <GridCell tablet={12} phone={12} desktop={dMin ? 3 : 2}>
          {getInners()}
        </GridCell>
      )}
      {!showNavigation && (
        <>
          <GridCell tablet={12} phone={12}>
            {mobileHidden()}
          </GridCell>
        </>
      )}
    </>
  );
}
