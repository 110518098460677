import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import css from '../main/dashboard.module.scss';
import Section from 'components/Section';
import { formatPhoneNumber } from 'src/helpers/utils';
import NavBar from 'modules/navBar/navbar.js';
import {
  DataTable,
  DataTableBody,
  DataTableContent,
  DataTableCell,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow,
} from '@rmwc/data-table';
import sharedCss from '../main/sharedCss.module.scss';
import ClientDialog from 'modules/clientList/ClientDialog';
import DeleteClientDialog from 'modules/clientList/DeleteClientDialog';
import {
  getClients,
  searchAllClientColumns,
  setSliceClient,
} from 'store/slices/clientsSlice';
import { filterByText } from 'helpers/filter';
import { compose } from 'helpers/utils';
import { Icon } from '@rmwc/icon';
import { useHistory } from 'react-router';
import useDetectScreenSizeGranular, {
  screenSizeGranular,
} from 'hooks/useMedia';
import Gravatar from 'react-awesome-gravatar';
import unknownAvatar from 'src/images/notFoundAvatar.jpg';

export default function ClientList() {
  const dispatch = useDispatch();
  const [client, setClient] = useState();
  const [open, setOpen] = useState();
  const [deleteOpen, setDeleteOpen] = useState();
  const { allColumnsSearchQuery } = useSelector(mapState, shallowEqual);
  const { clientList } = useSelector(getClientsFromState, shallowEqual);
  const history = useHistory();
  const screenSize = useDetectScreenSizeGranular();
  const dMin = screenSizeGranular.desktopMin === screenSize;
  const user = useSelector(state => state.user.user);
  const isCloudBased = user.tenantId !== undefined;

  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  function addClient() {
    setClient(undefined);
    setOpen(true);
  }
  function onClose() {
    setOpen(false);
  }

  function clientRow(clientItem) {
    return (
      <DataTableRow key={clientItem.id}>
        <DataTableCell>
          <Icon
            icon={{ icon: 'edit', size: 'xsmall' }}
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              setClient(clientItem);
              setOpen(true);
            }}
          />
          <Icon
            icon={{ icon: 'delete', size: 'xsmall' }}
            style={{ color: 'red', cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => {
              setClient(clientItem);
              setDeleteOpen(true);
            }}
          />
        </DataTableCell>
        <DataTableCell>
          {clientItem.email ? (
            <Gravatar email={clientItem.email}>
              {url => (
                <img
                  src={url}
                  style={{
                    height: '36px',
                    borderRadius: '50%',
                    verticalAlign: 'middle',
                  }}
                />
              )}
            </Gravatar>
          ) : (
            <img
              src={unknownAvatar}
              style={{
                height: '36px',
                borderRadius: '50%',
                verticalAlign: 'middle',
              }}
            />
          )}
        </DataTableCell>
        <DataTableCell>{clientItem.firstName}</DataTableCell>
        <DataTableCell>{clientItem.lastName}</DataTableCell>
        <DataTableCell>
          {formatPhoneNumber(clientItem.phone)}{' '}
          <Icon
            onClick={() => {
              dispatch(setSliceClient({ dispatch, client: clientItem }));
              history.push('/individualConversation');
            }}
            icon={{ icon: 'textsms', size: 'xsmall' }}
            style={{ color: 'blue', cursor: 'pointer' }}
          />
        </DataTableCell>
        <DataTableCell>{clientItem.email}</DataTableCell>
        <DataTableCell>{clientItem.company}</DataTableCell>
        <DataTableCell>{clientItem.address}</DataTableCell>
        <DataTableCell>{clientItem.city}</DataTableCell>
        <DataTableCell>{clientItem.state}</DataTableCell>
        <DataTableCell>{clientItem.zip}</DataTableCell>
      </DataTableRow>
    );
  }

  function getClientList() {
    return clientList && !isCloudBased
      ? clientList.map(clientItem => clientRow(clientItem))
      : clientList
          .filter(x => x.tenantId === user.tenantId)
          .map(clientItem => clientRow(clientItem));
  }

  return (
    <>
      <NavBar />
      <ClientDialog
        client={client}
        open={open}
        onClose={onClose}
        setOpen={setOpen}
      />
      <DeleteClientDialog
        client={client}
        open={deleteOpen}
        onClose={onClose}
        setOpen={setDeleteOpen}
      />
      <Section
        name="Contacts"
        span={dMin ? 9 : 10}
        className={css.dashboard}
        headerButton="Add Contact"
        headerButtonFunc={addClient}
        searchFunc={searchAllClientColumns}
        searchFuncValue={allColumnsSearchQuery}
      >
        <DataTable
          style={{ width: '100%', maxHeight: '75vh' }}
          className={sharedCss.fullWidth}
        >
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell />
                <DataTableHeadCell />
                <DataTableHeadCell>First Name</DataTableHeadCell>
                <DataTableHeadCell>Last Name</DataTableHeadCell>
                <DataTableHeadCell>Phone</DataTableHeadCell>
                <DataTableHeadCell>Email</DataTableHeadCell>
                <DataTableHeadCell>Company</DataTableHeadCell>
                <DataTableHeadCell>Address</DataTableHeadCell>
                <DataTableHeadCell>City</DataTableHeadCell>
                <DataTableHeadCell>State</DataTableHeadCell>
                <DataTableHeadCell>Zip</DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>{getClientList()}</DataTableBody>
          </DataTableContent>
        </DataTable>
      </Section>
    </>
  );
}

function mapState(state) {
  return {
    allColumnsSearchQuery: state.clients.allColumnsSearchQuery,
  };
}

function getClientsFromState(state) {
  const { allColumnsSearchQuery } = state.clients;
  const clients = state.clients?.clients?.slice();
  if (!clients) {
    return [];
  }

  return {
    clientList: compose(
      filterByText(allColumnsSearchQuery, [
        'fullName',
        'lastName',
        'phone',
        'email',
      ])
    )(clients),
  };
}
