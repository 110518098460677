import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@rmwc/button';
import classnames from 'classnames';
import css from './login.module.scss';
import { GridCell } from '@rmwc/grid';
import Section from '../../components/Section';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import { TextField } from '@rmwc/textfield';
import { useFormik } from 'formik';
import { loginUser } from 'store/slices/userSlice';
import { useHistory } from 'react-router';
import { showTextCenter } from 'store/slices/textCenterSlice';
//import { getPublicRegistration } from 'store/slices/settingsSlice';
//import { GoogleLogin } from 'react-google-login';

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const invalidToken = useSelector(state => state.user.invalidToken);
  /*const publicRegistration = useSelector(
    state => state.settings.publicRegistration
  );
  const pubReg = publicRegistration === '0' ? false : true;
   */
  const publicRegistration = false;
  const pubReg = false;

  /*
  useEffect(() => {
    dispatch(getPublicRegistration());
    // eslint-disable-next-line
  }, []);

                  <GoogleLogin
                  // eslint-disable-next-line
                  clientId=
"1005702810634-iso1rd54touougc6fh5if9du6ik5lq01.apps.googleusercontent.com"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  className={css.googleButton}
                />
   */

  const { setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
    },
    onSubmit: valuesForSave => {
      dispatch(showTextCenter());
      dispatch(loginUser({ dispatch, login: valuesForSave }));
    },
  });

  useEffect(() => {
    const handler = function(event) {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!invalidToken) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [invalidToken]);

  /*
  const responseGoogle = response => {
    const loginObj = {
      profileObj: response.profileObj,
      accessToken: response.accessToken,
      tokenId: response.tokenId,
      isGoogleLogin: true,
    };
    dispatch(loginUser({ dispatch, login: loginObj }));
  };
  */

  return (
    <>
      <GridCell span={4} />
      <GridCell span={4}>
        <Section name="Login" className={css.loginForm}>
          {publicRegistration !== undefined && (
            <>
              <TextField
                outlined
                label="Email"
                name="email"
                onChange={evt => {
                  setFieldValue('email', evt.target.value);
                }}
                className={classnames(sharedCss.wide, sharedCss.marginTop)}
              />
              <TextField
                outlined
                label="Password"
                name="password"
                type="password"
                onChange={evt => {
                  setFieldValue('password', evt.target.value);
                }}
                className={classnames(sharedCss.wide, sharedCss.marginTop)}
              />
              <hr className={sharedCss.marginTop} />
              <div>
                {publicRegistration && pubReg && (
                  <Button
                    outlined
                    theme={['secondaryBg', 'onSecondary']}
                    onClick={() => {
                      history.push('/register');
                    }}
                    className={css.floatRight}
                    label="Register"
                  />
                )}
                <Button
                  outlined
                  raised
                  theme={['secondaryBg', 'onSecondary']}
                  onKeyUp
                  onClick={() => {
                    handleSubmit();
                  }}
                  label="Login"
                />
              </div>
            </>
          )}
        </Section>
      </GridCell>
      <GridCell span={4} />
    </>
  );
}
