import React, { useState } from 'react';
import css from '../main/dashboard.module.scss';
import Section from 'components/Section';
import NavBar from 'modules/navBar/navbar.js';
import { Grid, GridCell } from '@rmwc/grid';
import TemplateList from 'modules/templates/TemplateList';
import Template from 'modules/templates/Template';
import TemplateDialog from 'modules/templates/TemplateDialog';
import useDetectScreenSizeGranular, {
  screenSizeGranular,
  IsMobile,
} from 'hooks/useMedia';

export default function Templates() {
  const [template, setTemplate] = useState();
  const [open, setOpen] = useState();
  const screenSize = useDetectScreenSizeGranular();
  const dMin = screenSizeGranular.desktopMin === screenSize;
  const isMobile = IsMobile();

  function addTemplate() {
    setTemplate(undefined);
    setOpen(true);
  }
  function onClose() {
    setOpen(false);
  }
  function setTemplateAndOpen(temp) {
    setTemplate(temp);
    if (isMobile) {
      setOpen(true);
    }
  }

  return (
    <>
      <NavBar />
      <Section
        name="Templates"
        span={dMin ? 9 : 10}
        className={css.dashboard}
        headerButton={isMobile ? 'Add Template' : undefined}
        headerButtonFunc={() => {
          if (isMobile) {
            addTemplate();
          }
        }}
      >
        <Grid>
          <GridCell phone={12} tablet={12} span={5}>
            <TemplateList
              setTemplate={isMobile ? setTemplateAndOpen : setTemplate}
            />
          </GridCell>
          {!isMobile && (
            <GridCell span={7}>
              <Template template={template} setTemplate={setTemplate} />
            </GridCell>
          )}
          {isMobile && (
            <TemplateDialog
              template={template}
              open={open}
              onClose={onClose}
              setOpen={setOpen}
            />
          )}
        </Grid>
      </Section>
    </>
  );
}
