import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridCell } from '@rmwc/grid';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { registerUser } from 'store/slices/userSlice';
import classnames from 'classnames';
import css from './userList.module.scss';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import { Button } from '@rmwc/button';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import FormikTextField from '../../components/FormikTextField';
import {
  permissionLevelsOptions2,
  permissionLevelsOptions1,
  permissionLevels,
} from 'src/enums/roleEnums';
import FormikSelect from 'src/components/FormikSelect';
import * as Yup from 'yup';

User.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
};

export default function User({ user, setUser }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.user.user);

  const formikBag = useFormik({
    initialValues: user
      ? user
      : {
          name: '',
          username: '',
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          phone: '',
          permissionLevel: '0',
        },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      username: Yup.string().required('Please include a username'),
      password: user
        ? undefined
        : Yup.string().required('Please include a password'),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
      phone: Yup.string().required('Please include a phone'),
      firstName: Yup.string().required('Please include a first name'),
      lastName: Yup.string().required('Please include a last name'),
      email: Yup.string().required('Please include an email'),
    }),
    onSubmit: valuesForSave => {
      dispatch(
        registerUser({
          dispatch,
          registration: valuesForSave,
          callGetUsers: true,
        })
      );
    },
  });

  const { handleSubmit, values } = formikBag;
  return (
    <>
      <Grid style={{ padding: '0px' }}>
        <GridCell span={6}>
          <Typography use="headline6" tag="h2">
            {user
              ? `Editing User: ${user.firstName} ${user.lastName}`
              : 'New User'}
          </Typography>
        </GridCell>
        <GridCell span={6}>
          <Button
            outlined
            raised
            className={classnames(css.floatRight)}
            theme={['secondaryBg', 'onSecondary']}
            onClick={() => {
              setUser(undefined);
            }}
            label="New User"
          />
        </GridCell>
      </Grid>
      <hr />
      <Grid>
        <GridCell span={4}>
          <FormikTextField
            label="Username"
            formikName="username"
            formikBag={formikBag}
            required
            autoComplete="off"
            className={sharedCss.marginTop}
          />
        </GridCell>
        <GridCell span={4}>
          <FormikTextField
            label="First Name"
            formikName="firstName"
            formikBag={formikBag}
            required
            autoComplete="off"
            className={sharedCss.marginTop}
          />
        </GridCell>
        <GridCell span={4}>
          <FormikTextField
            label="Last Name"
            formikName="lastName"
            formikBag={formikBag}
            required
            autoComplete="off"
            className={sharedCss.marginTop}
          />
        </GridCell>
        <GridCell span={6}>
          <FormikTextField
            label="Email"
            formikName="email"
            formikBag={formikBag}
            required
            autoComplete="off"
            className={sharedCss.marginTop}
          />
        </GridCell>
        <GridCell span={6}>
          <FormikTextField
            label="Sending Phone Number"
            formikName="phone"
            formikBag={formikBag}
            required
            autoComplete="off"
            className={sharedCss.marginTop}
          />
        </GridCell>
        <GridCell span={6}>
          <FormikTextField
            label="Password"
            formikName="password"
            formikBag={formikBag}
            type="password"
            required={user === undefined}
            autoComplete="off"
            className={sharedCss.marginTop}
          />
        </GridCell>
        <GridCell span={6}>
          <FormikTextField
            label="Confirm Password"
            formikName="confirmPassword"
            formikBag={formikBag}
            type="password"
            required={values?.password?.length || !user}
            autoComplete="off"
            className={sharedCss.marginTop}
          />
        </GridCell>
        <GridCell span={6}>
          {values && (
            <FormikSelect
              label="Permission Level"
              formikName="permissionLevel"
              formikBag={formikBag}
              default
              options={
                loggedInUser.permissionLevel.toString() ===
                permissionLevels.superAdmin
                  ? permissionLevelsOptions2
                  : permissionLevelsOptions1
              }
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
              required
            />
          )}
        </GridCell>
      </Grid>
      <hr className={sharedCss.marginTop} />
      <Button
        outlined
        theme={['secondaryBg', 'onSecondary']}
        label="Cancel"
        onClick={() => {
          setUser(undefined);
        }}
      />
      <Button
        outlined
        raised
        className={classnames(css.floatRight)}
        theme={['secondaryBg', 'onSecondary']}
        onClick={() => {
          handleSubmit();
        }}
        label={user ? 'Submit' : 'Create'}
      />
    </>
  );
}
