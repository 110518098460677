export function transformSettings(settings) {
  return settings.map(transformSetting);
}

export function transformSetting(setting) {
  if (!setting) {
    return setting;
  }
  const newSetting = setting.setting;
  const autoResponse = setting.automatedResponse;
  setting.setting = {
    ...newSetting,
    publicRegistration: newSetting?.publicRegistration === '1' || false,
    automatedResponse: autoResponse ?? '',
  };
  return setting;
}
