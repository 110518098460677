import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import errorReducer, { setError } from 'store/slices/errorSlice';

export function createStore(reducers, sagas) {
  const sagaMiddleware = createSagaMiddleware({
    onError(error, stack) {
      console.error(error, stack);
      store.dispatch(setError({ error }));
    },
  });

  const store = configureStore({
    reducer: combineReducers({ ...reducers, error: errorReducer }),
    middleware: [sagaMiddleware],
  });

  sagaMiddleware.run(function*() {
    yield all([...sagas]);
  });

  return store;
}
