import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { ListItem } from '@rmwc/list';
import {
  showTextCenter,
  showGroupTextCenter,
  removeGroupTextCenter,
  removeTextCenter,
} from 'store/slices/textCenterSlice';
import { hideNavBar } from 'src/store/slices/settingsSlice';
import { IsMobile } from 'hooks/useMedia';

NavItem.propTypes = {
  newPath: PropTypes.string,
  label: PropTypes.string,
  showTextCenterIframe: PropTypes.bool,
  onClick: PropTypes.func,
};

export default function NavItem({
  newPath,
  label,
  showTextCenterIframe = false,
  onClick,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const isActive = path === '/' + newPath;
  const isMobile = IsMobile();

  function handlMobileNav() {
    if (isMobile) {
      dispatch(hideNavBar());
    }
  }

  function handleRedirect() {
    switch (path) {
      case '/textCenter':
        dispatch(removeTextCenter({ directTo: newPath }));
        if (newPath === 'groupTexting') {
          dispatch(showGroupTextCenter());
        }
        handlMobileNav();
        break;
      case '/groupTexting':
        dispatch(removeGroupTextCenter({ directTo: newPath }));
        if (newPath === 'textCenter') {
          dispatch(showTextCenter());
        }
        handlMobileNav();
        break;
      default:
        if (showTextCenterIframe) {
          if (newPath === 'textCenter') {
            dispatch(showTextCenter());
          } else if (newPath === 'groupTexting') {
            dispatch(showGroupTextCenter());
          }
        }
        history.push('/' + newPath);
        handlMobileNav();
        break;
    }
  }

  return (
    <ListItem
      activated={isActive}
      disabled={isActive}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        if (path !== '/' + newPath) {
          handleRedirect();
        }
      }}
    >
      {label}
    </ListItem>
  );
}
