import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from '@rmwc/dialog';
import { useFormik } from 'formik';
import { deleteClient } from 'store/slices/clientsSlice';
import css from './clientList.module.scss';

DeleteClientDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

export default function DeleteClientDialog({ client, open, setOpen, onClose }) {
  const dispatch = useDispatch();

  const { handleSubmit } = useFormik({
    initialValues: client,
    enableReinitialize: true,
    onSubmit: valuesForSave => {
      dispatch(deleteClient({ dispatch, client: valuesForSave }));
      setOpen(false);
    },
  });

  if (!open) {
    return null;
  }

  return (
    <Dialog open={true} className="deleteClientDialog">
      <DialogTitle>Delete Contact?</DialogTitle>
      <DialogContent className={css.clientDialog}>
        <hr />
        <div>
          Are you sure that you want to delete this contact? Doing so will also
          remove all conversation history.
        </div>
        <hr />
      </DialogContent>
      <DialogActions>
        <DialogButton
          style={{ float: 'left' }}
          onClick={onClose}
          action="close"
        >
          Cancel
        </DialogButton>
        <DialogButton action="Submit" onClick={handleSubmit} raised>
          Delete
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
