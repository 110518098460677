import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from '@rmwc/dialog';
import { useFormik } from 'formik';
import { saveClient } from 'store/slices/clientsSlice';
import { Grid, GridCell } from '@rmwc/grid';
import { TextField } from '@rmwc/textfield';
import classnames from 'classnames';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import css from './clientList.module.scss';

ClientDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  client: PropTypes.object,
};

export default function ClientDialog({
  client = undefined,
  open,
  setOpen,
  onClose,
}) {
  const dispatch = useDispatch();

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: client
      ? client
      : {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          address: '',
          company: '',
          city: '',
          state: '',
          zip: '',
        },
    enableReinitialize: true,
    onSubmit: valuesForSave => {
      dispatch(saveClient({ dispatch, client: valuesForSave }));
      setOpen(false);
    },
  });

  if (!open) {
    return null;
  }

  return (
    <Dialog open={true} className="clientDialog">
      <DialogTitle>{client ? 'Edit Client' : 'New Client'}</DialogTitle>
      <DialogContent className={css.clientDialog}>
        <hr />
        <Grid>
          <GridCell span={6}>
            <TextField
              outlined
              label="First Name"
              name="firstName"
              value={values.firstName}
              onChange={evt => {
                setFieldValue('firstName', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
          <GridCell span={6}>
            <TextField
              outlined
              label="Last Name"
              name="lastName"
              value={values.lastName}
              onChange={evt => {
                setFieldValue('lastName', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
          <GridCell span={4}>
            <TextField
              outlined
              label="Phone"
              name="phone"
              type="phone"
              value={values.phone}
              onChange={evt => {
                setFieldValue('phone', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
          <GridCell span={4}>
            <TextField
              outlined
              label="Email"
              name="email"
              value={values.email}
              onChange={evt => {
                setFieldValue('email', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
          <GridCell span={4}>
            <TextField
              outlined
              label="Company"
              name="company"
              value={values.company}
              onChange={evt => {
                setFieldValue('company', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
          <GridCell span={12}>
            <TextField
              outlined
              label="Address"
              name="address"
              type="text"
              value={values.address}
              onChange={evt => {
                setFieldValue('address', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
          <GridCell span={6}>
            <TextField
              outlined
              label="City"
              name="city"
              type="text"
              value={values.city}
              onChange={evt => {
                setFieldValue('city', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
          <GridCell span={4}>
            <TextField
              outlined
              label="State"
              name="state"
              type="text"
              value={values.state}
              onChange={evt => {
                setFieldValue('state', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
          <GridCell span={2}>
            <TextField
              outlined
              label="Zip"
              name="zip"
              type="text"
              value={values.zip}
              onChange={evt => {
                setFieldValue('zip', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
        </Grid>
        <hr />
      </DialogContent>
      <DialogActions>
        <DialogButton
          style={{ float: 'left' }}
          onClick={onClose}
          action="close"
        >
          Cancel
        </DialogButton>
        <DialogButton action="Submit" onClick={handleSubmit} raised>
          Submit
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
