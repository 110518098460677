export async function getErrorMessage(response) {
  const responseText = await response.text();
  const err = responseText
    ? await JSON.parse(responseText)
    : { title: `Empty response with http status ${response.status}` };

  const multipleErrorMessage = 'One or more validation errors occurred.';
  const title = err.title || err.Title || err.message || err.Message;
  const errors = err.errors || err.Errors;

  if (title && title !== multipleErrorMessage) {
    throw new Error(title);
  }

  if (title && title === multipleErrorMessage && errors) {
    const errorMessage = `${multipleErrorMessage}: ${
      errors[Object.keys(errors)[0]]
    }`;

    throw new Error(errorMessage);
  }

  throw new Error(`Oops, an error occurred: ${responseText}`);
}
