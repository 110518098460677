import React, { useState } from 'react';
import css from '../main/dashboard.module.scss';
import Section from 'components/Section';
import NavBar from 'modules/navBar/navbar.js';
import { Grid, GridCell } from '@rmwc/grid';
import UserList from 'modules/administration/UserList';
import User from 'modules/administration/User';
import UserDialog from 'modules/administration/UserDialog';
import useDetectScreenSizeGranular, {
  screenSizeGranular,
  IsMobile,
} from 'hooks/useMedia';

export default function Administration() {
  const [user, setUser] = useState();
  const [open, setOpen] = useState();
  const screenSize = useDetectScreenSizeGranular();
  const dMin = screenSizeGranular.desktopMin === screenSize;

  const isMobile = IsMobile();

  function addUser() {
    setUser(undefined);
    setOpen(true);
  }
  function onClose() {
    setOpen(false);
  }
  function setUserAndOpen(temp) {
    setUser(temp);
    if (isMobile) {
      setOpen(true);
    }
  }

  return (
    <>
      <NavBar />
      <Section
        name="Administration"
        span={dMin ? 9 : 10}
        className={css.dashboard}
        headerButton={isMobile ? 'Add User' : undefined}
        headerButtonFunc={() => {
          addUser();
        }}
      >
        <Grid>
          <GridCell phone={12} tablet={12} span={6}>
            <UserList
              editUser={user}
              setUser={isMobile ? setUserAndOpen : setUser}
            />
          </GridCell>
          {!isMobile && (
            <GridCell span={6}>
              <User user={user} setUser={setUser} />
            </GridCell>
          )}
          {isMobile && (
            <UserDialog
              user={user}
              open={open}
              onClose={onClose}
              setOpen={setOpen}
            />
          )}
        </Grid>
      </Section>
    </>
  );
}
