import { createSlice } from '@reduxjs/toolkit';

const textCenterSlice = createSlice({
  name: 'textCenter',
  initialState: {
    showTextCenter: true,
    showGroupTextCenter: false,
  },
  reducers: {
    showTextCenter(state) {
      state.showTextCenter = true;
    },
    removeTextCenter(state, action) {
      state.showTextCenter = false;
      if (action.payload.directTo) {
        state.directTo = action.payload.directTo;
      }
    },
    showGroupTextCenter(state) {
      state.showGroupTextCenter = true;
    },
    removeGroupTextCenter(state, action) {
      state.showGroupTextCenter = false;
      if (action.payload.directTo) {
        state.directTo = action.payload.directTo;
      }
    },
  },
});

export const {
  showTextCenter,
  removeTextCenter,
  showGroupTextCenter,
  removeGroupTextCenter,
} = textCenterSlice.actions;

export default textCenterSlice.reducer;
