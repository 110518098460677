import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    hasError: false,
    errorMessage: undefined,
    savedSettings: undefined,
    publicRegistration: undefined,
    showNav: false,
  },
  reducers: {
    getSettings() {},
    saveSettings() {},
    setSettings(state, action) {
      state.savedSettings = action.payload.settings;
    },
    showNavBar(state) {
      state.showNav = true;
    },
    hideNavBar(state) {
      state.showNav = false;
    },
    getPublicRegistration() {},
    setPublicRegistration(state, action) {
      state.publicRegistration = action.payload.publicRegistration;
    },
  },
});

export const {
  saveSettings,
  setSettings,
  getSettings,
  getPublicRegistration,
  setPublicRegistration,
  showNavBar,
  hideNavBar,
} = settingsSlice.actions;

export default settingsSlice.reducer;
