/* eslint-disable */
export default {
  publicPath: process.env.PUBLIC_PATH,
  smsManagementBackendUrl: process.env.SMS_MANAGEMENT_BACKEND_URL,
  smsCoreUrl: process.env.SMS_CORE_URL,
  smsCoreBackendUrl: process.env.SMS_CORE_BACKEND_URL,
  jwtToken: '8m3Dl4sQ3efnW6vg4uSsBne6UKZYUEUVzLmiAXsFpsNL2SufrzgiRdSwvdIVNonr',
  smsMgmtEchoKey: process.env.SMS_MGMT_ECHO_KEY,
  smsMgmtEchoCluster: process.env.SMS_MGMT_ECHO_CLUSTER,
  smsMgmtEchoHost: process.env.SMS_MGMT_ECHO_HOST.replace(/(^\w+:|^)\/\//, ''),
};
