import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Elevation } from '@rmwc/elevation';
import { Typography } from '@rmwc/typography';
import EditAvatarDialog from './EditAvatarDialog';
import { Grid, GridCell } from '@rmwc/grid';
import { Button } from '@rmwc/button';

import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import css from '../../components/section.module.scss';
import { useSelector } from 'react-redux';
import Gravatar from 'react-awesome-gravatar';
import config from 'src/helpers/config';
import { formatPhoneNumber } from 'src/helpers/utils';

ProfileNavSection.propTypes = {
  name: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  // These define grid sizing for the whole section.
  // If defined, the default behavior (full width), will
  // not be applied.
  span: PropTypes.number,
  desktop: PropTypes.number,
  tablet: PropTypes.number,
  phone: PropTypes.number,
  // applied to elevation
  className: PropTypes.string,
  headerButton: PropTypes.func,
};

function ProfileNavSection({
  name,
  span,
  desktop,
  tablet,
  phone,
  className,
  headerButton,
}) {
  const anySizing =
    span !== undefined ||
    desktop !== undefined ||
    tablet !== undefined ||
    phone !== undefined;

  const user = useSelector(state => state.user.user);
  const forceReRender = useSelector(state => state.user.forceReRenderImg);
  const [showEditAvatar, setShowEditAvatar] = useState(false);

  function onClose() {
    setShowEditAvatar(false);
  }
  return (
    <>
      <EditAvatarDialog onClose={onClose} open={showEditAvatar} />
      <GridCell
        span={!anySizing ? 12 : span}
        desktop={desktop}
        tablet={tablet}
        phone={phone}
      >
        <Elevation
          z={1}
          className={classnames(
            css.relative,
            sharedCss.backgroundSurface,
            className
          )}
        >
          <Grid className={sharedCss.noPadding}>
            {name && (
              <>
                <GridCell span={9}>
                  <Typography use="headline6" tag="h2">
                    {!user.avatar ? (
                      <Gravatar email={user.email}>
                        {url => (
                          <img src={url} style={{ borderRadius: '50%' }} />
                        )}
                      </Gravatar>
                    ) : (
                      <img
                        style={{
                          height: '80px',
                          width: '80px',
                          borderRadius: '50%',
                        }}
                        src={
                          config.smsManagementBackendUrl +
                          '/api/getAvatar/' +
                          user.id +
                          '?t=' +
                          forceReRender
                        }
                      />
                    )}
                    {headerButton && (
                      <Button
                        label="Logout"
                        onClick={() => {
                          headerButton();
                        }}
                        style={{ float: 'right' }}
                        outlined
                      />
                    )}
                    <Button
                      label="Edit Avatar"
                      onClick={() => {
                        setShowEditAvatar(true);
                      }}
                      style={{ marginLeft: '-15px' }}
                    />
                  </Typography>
                </GridCell>
              </>
            )}
          </Grid>
          <hr />
          <div>
            <Typography use="subtitle1">Username: </Typography>
            <Typography use="subtitle2" style={{ float: 'right' }}>
              {user.name}{' '}
            </Typography>
          </div>
          <div>
            <Typography use="subtitle1">First Name:</Typography>
            <Typography use="subtitle2" style={{ float: 'right' }}>
              {user.firstName}
            </Typography>
          </div>
          <div>
            <Typography use="subtitle1">Last Name:</Typography>
            <Typography use="subtitle2" style={{ float: 'right' }}>
              {user.lastName}
            </Typography>
          </div>
          <div>
            <Typography use="subtitle1">Phone:</Typography>
            <Typography use="subtitle2" style={{ float: 'right' }}>
              {formatPhoneNumber(user.phone)}
            </Typography>
          </div>
        </Elevation>
      </GridCell>
    </>
  );
}

export default ProfileNavSection;
