import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from '@rmwc/dialog';
import { useFormik } from 'formik';
import { saveTemplate } from 'store/slices/templatesSlice';
import { Grid, GridCell } from '@rmwc/grid';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import css from './templateList.module.scss';
import FormikTextField from 'src/components/FormikTextField';
import * as Yup from 'yup';

TemplateDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  template: PropTypes.object,
};

export default function TemplateDialog({
  template = undefined,
  open,
  onClose,
}) {
  const dispatch = useDispatch();

  const formikBag = useFormik({
    initialValues: template
      ? template
      : {
          name: '',
          description: '',
          message: '',
        },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please include a name'),
      description: Yup.string().required('Please include a description'),
      message: Yup.string().required('Please include an message'),
    }),
    onSubmit: valuesForSave => {
      dispatch(
        saveTemplate({
          dispatch,
          template: valuesForSave,
        })
      );
      onClose();
    },
  });

  const { handleSubmit } = formikBag;

  if (!open) {
    return null;
  }

  return (
    <Dialog open={true} className="templateDialog">
      <DialogTitle>{template ? 'Edit Template' : 'New Template'}</DialogTitle>
      <DialogContent className={css.templateDialog}>
        <hr />
        <Grid>
          <GridCell span={4}>
            <FormikTextField
              label="Name"
              formikName="name"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
          </GridCell>
          <GridCell span={4}>
            <FormikTextField
              label="Description"
              formikName="description"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
          </GridCell>
          <GridCell span={12}>
            <FormikTextField
              label="Message"
              formikName="message"
              formikBag={formikBag}
              textarea
              rows={5}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
              fullwidth
            />
          </GridCell>
        </Grid>
        <hr />
      </DialogContent>
      <DialogActions>
        <DialogButton
          style={{ float: 'left' }}
          onClick={onClose}
          action="close"
        >
          Cancel
        </DialogButton>
        <DialogButton action="Submit" onClick={handleSubmit} raised>
          Submit
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
