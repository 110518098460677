import { createSnackbarQueue } from '@rmwc/snackbar';

const queue = createSnackbarQueue();

export function notifyError(msg, dispatch, actionForRetry) {
  queue.notify({
    body: msg,
    dismissesOnAction: true,
    actions: [
      {
        title: 'RETRY',
        onClick: () => {
          dispatch(actionForRetry);
        },
      },
    ],
  });
}

export default queue;
