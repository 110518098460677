import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    invalidToken: true,
    token: undefined,
    textCenterToken: undefined,
    user: undefined,
    redirectToLogin: false,
    notifications: false,
    newNotificationsExist: false,
    forceReRenderImg: 0,
    desktopNotification: undefined,
  },
  reducers: {
    invalidateToken(state) {
      state.invalidToken = true;
    },
    registerUser() {},
    deleteUser() {},
    loginUser(state) {
      state.token = undefined;
      state.user = undefined;
      state.redirectToLogin = false;
    },
    logoutUser(state) {
      state.user = undefined;
      state.token = undefined;
      state.invalidToken = true;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    },
    setUser(state, action) {
      state.user = action.payload.user;
      state.invalidToken = false;
      state.token = action.payload.user.token;
    },
    setToken(state, action) {
      state.token = action.payload.token;
      if (!action.payload?.response?.error) {
        state.user = action.payload.user;

        state.invalidToken = false;
        if (action.payload.token) {
          localStorage.setItem('token', action.payload.token);
          state.token = action.payload.token;
        }

        if (action.payload.textCenterToken) {
          state.textCenterToken = action.payload.textCenterToken;
          localStorage.setItem(
            'textCenterToken',
            action.payload.textCenterToken
          );
        }

        localStorage.setItem('user', JSON.stringify(action.payload.user));
      }
    },
    setTextCenterToken(state, action) {
      state.textCenterToken = action.payload.textCenterToken;
      localStorage.setItem('textCenterToken', action.payload.textCenterToken);
    },
    redirectToLogin(state) {
      state.redirectToLogin = true;
    },
    clearRedirectToLogin(state) {
      state.redirectToLogin = false;
    },
    setNotifications(state, action) {
      state.notifications = action.payload.notifications;
    },
    clearNewNotificationsExist(state) {
      state.newNotificationsExist = false;
    },
    setNewNotificationsExist(state) {
      state.newNotificationsExist = true;
    },
    setAvatar(state) {
      state.forceReRenderImg = state.forceReRenderImg + 1;
    },
    setDesktopNotification(state, action) {
      state.desktopNotification = action.payload.desktopNotification;
    },
    clearDesktopNotification(state) {
      state.desktopNotification = undefined;
    },
    submitSupportTicket() {},
    saveAvatar() {},
    checkNotifications() {},
  },
});

export const {
  setUser,
  registerUser,
  deleteUser,
  loginUser,
  invalidateToken,
  setToken,
  logoutUser,
  redirectToLogin,
  clearRedirectToLogin,
  setTextCenterToken,
  setNotifications,
  checkNotifications,
  saveAvatar,
  setAvatar,
  setDesktopNotification,
  clearDesktopNotification,
  submitSupportTicket,
} = userSlice.actions;

export default userSlice.reducer;
