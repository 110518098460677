import { compose } from 'helpers/utils';

export function filterByText(filterText) {
  return arr => {
    if (!filterText) {
      return arr;
    }

    return arr.filter(x =>
      compose(
        anyContain(filterText.toLowerCase()),
        stringsToLowerCase,
        getPropertyValues
      )(x)
    );
  };
}

function eq(a) {
  return b => a === b;
}

function prop2(key) {
  return obj => obj[key];
}

export function filterByPropEquals(propertyKey, compareValue) {
  return arr => {
    if (!compareValue) {
      return arr;
    }

    const filterFn = compose(eq(compareValue), prop2(propertyKey));

    return arr.filter(filterFn);
  };
}

export function contains(textToCheck) {
  return x => ('' + x).indexOf(textToCheck) > -1;
}

export function anyContain(textToCheck) {
  return arr => arr.some(contains(textToCheck));
}

export function prop(obj) {
  return p => obj[p];
}

export function getPropertyValues(obj) {
  return Object.keys(obj).map(prop(obj));
}

// eslint-disable-next-line no-unused-vars
function debug(name) {
  return x => {
    console.log(name, x);
    return x;
  };
}

export function stringsToLowerCase(arr) {
  return arr.map(item => {
    if (typeof item !== 'string') {
      return item;
    }

    return item.toLowerCase();
  });
}
