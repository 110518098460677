import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as api from 'src/api';
import {
  registerUser,
  deleteUser,
  loginUser,
  setToken,
  redirectToLogin,
  checkNotifications,
  setNotifications,
  saveAvatar,
  setAvatar,
  submitSupportTicket,
} from '../slices/userSlice';
import { showTextCenter } from '../slices/textCenterSlice';
import snackbarQueue from 'helpers/snackbarQueue';
import { callWithToken } from 'helpers/sagaHelpers';
import { getUsers } from 'store/slices/usersSlice';

function* registerUserSaga(action) {
  const { dispatch, registration, callGetUsers } = action.payload;
  let user;

  try {
    user = yield callWithToken(api.registerUser, registration);

    if (user.error) {
      throw user.error;
    }
    if (user.textCenterToken) {
      yield put(
        setToken({
          user: user.user,
          token: user.token,
          textCenterToken: user.textCenterToken,
        })
      );
    }

    const message = registration?.id
      ? user.textCenterToken
        ? 'User has successfully been edited.'
        : // eslint-disable-next-line max-len
          'User has successfully been edited. Please inform the user to log out and back in for the changes to be reflected.'
      : 'New user has successfully been created.';

    snackbarQueue.notify({
      body: message,
      dismissesOnAction: true,
    });
    if (callGetUsers) {
      dispatch(getUsers());
    } else {
      yield put(redirectToLogin());
    }
  } catch (e) {
    snackbarQueue.notify({
      body: e,
      dismissesOnAction: true,
    });
  }
}

function* loginUserSaga(action) {
  const { login } = action.payload;

  try {
    const response = yield call(api.loginUser, login);
    yield put(showTextCenter());
    yield put(
      setToken({
        token: response.access_token,
        user: response.user,
        response: response,
        textCenterToken: response.textCenterToken,
      })
    );
  } catch (e) {
    if (e.message.includes('SHOW')) {
      throw e;
    }
    snackbarQueue.notify({
      body:
        'Oops, there was an issue with your login. ' +
        'Please check your credentials and try again.',
      dismissesOnAction: true,
    });

    console.error('get authorization error', e);
    /*
    notifyError(
      'Sorry, there was a problem loading the page...',
      dispatch,
      registerUser(action.payload)
    );
     */
  }
}
function* saveUserAvatarSaga(action) {
  const { avatar } = action.payload;

  try {
    // eslint-disable-next-line
    const newAvatar = yield callWithToken(api.saveAvatar, avatar);
    yield put(setAvatar({}));

    snackbarQueue.notify({
      body: 'Your new avatar has successfully been saved...',
      dismissesOnAction: true,
    });
  } catch (e) {
    snackbarQueue.notify({
      body: 'There was an error saving your avatar...',
      dismissesOnAction: true,
    });
  }
}

function* deleteUserSaga(action) {
  const { dispatch, user } = action.payload;

  try {
    const users = yield callWithToken(api.deleteUser, user);
    if (!users.isValid) {
      throw new Error('error');
    }

    snackbarQueue.notify({
      body: 'User has been removed...',
      dismissesOnAction: true,
    });
    dispatch(getUsers());
  } catch (e) {
    snackbarQueue.notify({
      body:
        /* eslint-disable */
        'There was an error deleting your contact, if this continues please contact support...',
      dismissesOnAction: true,
    });
  }
}

function* checkNotificationsSaga() {
  try {
    const user = yield select(state => state.user.user);
    const notifications = yield callWithToken(api.checkNotifications, user.id);
    yield put(setNotifications({ notifications: notifications }));
  } catch (e) {
    console.log('ERROR');
  }
}

function* submitSupportTicketSaga(action) {
  const { supportTicket } = action.payload;

  try {
    // eslint-disable-next-line
    const ticket = yield callWithToken(api.submitSupportTicket, supportTicket);

    if (ticket.error) {
      throw ticket.error;
    }

    snackbarQueue.notify({
      body:
        // eslint-disable-next-line max-len
        'Your ticket has successfully been submitted, please check your email for updates moving forward...',
      dismissesOnAction: true,
    });
  } catch (e) {
    snackbarQueue.notify({
      body:
        // eslint-disable-next-line max-len
        'There was an error submitting your ticket, please email support directly...',
      dismissesOnAction: true,
    });
  }
}

export default function*() {
  yield all([
    yield takeLatest(registerUser.type, registerUserSaga),
    yield takeLatest(deleteUser.type, deleteUserSaga),
    yield takeLatest(loginUser.type, loginUserSaga),
    yield takeLatest(checkNotifications.type, checkNotificationsSaga),
    yield takeLatest(saveAvatar.type, saveUserAvatarSaga),
    yield takeLatest(submitSupportTicket.type, submitSupportTicketSaga),
  ]);
}
