import { useEffect, useState } from 'react';

export const screenSize = {
  desktop: 1,
  tablet: 2,
  phone: 3,
};

export const screenSizeGranular = {
  desktopMax: 0,
  desktopMin: 1,
  tablet: 2,
  phone: 3,
};

// From https://usehooks.com/
function useMedia(queries, values, defaultValue) {
  const mediaQueryLists = queries.map(q => window.matchMedia(q));

  const getValue = () => {
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);
    mediaQueryLists.forEach(mql => mql.addListener(handler));
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
    // eslint-disable-next-line
  }, []);

  return value;
}

export function useDetectScreenSize() {
  return useMedia(
    [
      '(min-width: 840px)',
      '(max-width: 839px) and (min-width: 600px)',
      '(max-width: 599px)',
    ],
    [screenSize.desktop, screenSize.tablet, screenSize.phone],
    screenSize.desktop
  );
}

export default function useDetectScreenSizeGranular() {
  return useMedia(
    [
      '(min-width: 1410px)',
      '(max-width: 1409px) and (min-width: 839px)',
      '(max-width: 839px) and (min-width: 600px)',
      '(max-width: 599px)',
    ],
    [
      screenSizeGranular.desktopMax,
      screenSizeGranular.desktopMin,
      screenSize.tablet,
      screenSize.phone,
    ],
    screenSizeGranular.desktopMax
  );
}
export function IsMobile() {
  const currentSize = useMedia(
    [
      '(min-width: 840px)',
      '(max-width: 839px) and (min-width: 600px)',
      '(max-width: 599px)',
    ],
    [screenSize.desktop, screenSize.tablet, screenSize.phone],
    screenSize.desktop
  );

  return [screenSize.tablet, screenSize.phone].includes(currentSize);
}
