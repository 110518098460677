import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@rmwc/button';
import classnames from 'classnames';
import css from './settings.module.scss';
import { GridCell } from '@rmwc/grid';
import Section from 'src/components/Section';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import { TextField } from '@rmwc/textfield';
import { useFormik } from 'formik';
import NavBar from 'modules/navBar/navbar.js';
import { useHistory } from 'react-router';
import { saveSettings, getSettings } from 'store/slices/settingsSlice';
import { Switch } from '@rmwc/switch';
import useDetectScreenSizeGranular, {
  screenSizeGranular,
} from 'hooks/useMedia';
import { formatPhoneNumber } from 'src/helpers/utils';

export default function Settings() {
  const history = useHistory();
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings.savedSettings?.setting);
  const screenSize = useDetectScreenSizeGranular();
  const dMin = screenSizeGranular.desktopMin === screenSize;
  const user = useSelector(state => state.user.user);
  const isCloudBased = user.tenantId !== undefined;
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    dispatch(getSettings());
    // eslint-disable-next-line
  }, []);

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: settings
      ? settings
      : {
          sid: '',
          secret: '',
          token: '',
          phone: '',
          publicRegistration: false,
          automatedResponse: '',
        },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: valuesForSave => {
      dispatch(saveSettings({ settings: valuesForSave }));
    },
  });

  return (
    <>
      <NavBar />
      <Section name="Settings" span={dMin ? 9 : 10} className={css.settings}>
        <div style={{ height: '70vh' }}>
          <TextField
            outlined
            label={
              'Automated Response for ' + formatPhoneNumber(user.phone) + ':'
            }
            name="automatedResponse"
            onChange={evt => {
              setFieldValue('automatedResponse', evt.target.value);
            }}
            value={values?.automatedResponse}
            className={classnames(sharedCss.wide, sharedCss.marginTop)}
          />
          <div className={css.advancedSettingsShow}>
            <hr />
            <Button
              outlined
              raised
              theme={['secondaryBg', 'onSecondary']}
              onClick={() => {
                setShowAdvanced(!showAdvanced);
              }}
              label={showAdvanced ? 'Hide Advanced' : 'Advanced Settings'}
            />
            <div
              className={!showAdvanced ? css.advancedSettingsHide : undefined}
            >
              <GridCell span={4} />
              <TextField
                outlined
                label="Skyetel SID"
                name="sid"
                onChange={evt => {
                  setFieldValue('sid', evt.target.value);
                }}
                value={values?.sid}
                className={classnames(sharedCss.wide, sharedCss.marginTop)}
              />
              <TextField
                outlined
                label="Skyetel Secret"
                name="secret"
                onChange={evt => {
                  setFieldValue('secret', evt.target.value);
                }}
                value={values?.secret}
                className={classnames(sharedCss.wide, sharedCss.marginTop)}
              />
              {!isCloudBased && (
                <Switch
                  label="Public Registration Form"
                  name="publicRegistration"
                  checked={values?.publicRegistration}
                  onChange={() => {
                    setFieldValue(
                      'publicRegistration',
                      !values?.publicRegistration
                    );
                  }}
                  className={classnames(sharedCss.wide, sharedCss.marginTop)}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <hr className={sharedCss.marginTop} />
          <Button
            outlined
            raised
            theme={['secondaryBg', 'onSecondary']}
            onClick={() => {
              handleSubmit();
            }}
            label="Save"
          />
          <Button
            outlined
            theme={['secondaryBg', 'onSecondary']}
            onClick={() => {
              history.push('/dashboard');
            }}
            className={css.floatRight}
            label="Cancel"
          />
        </div>
        <GridCell span={4} />
      </Section>
    </>
  );
}
