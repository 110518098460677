import React from 'react';
import css from './dashboard.module.scss';
import Section from '../../components/Section';
import NavBar from 'modules/navBar/navbar.js';
import useDetectScreenSizeGranular, {
  screenSizeGranular,
} from 'hooks/useMedia';

export default function Dashboard() {
  const screenSize = useDetectScreenSizeGranular();
  const dMin = screenSizeGranular.desktopMin === screenSize;

  return (
    <>
      <NavBar />
      <Section
        name="Dashboard"
        span={dMin ? 9 : 10}
        className={css.dashboard}
      />
    </>
  );
}
