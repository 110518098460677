import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select } from '@rmwc/select';

import sharedCss from 'src/styles/shared-ui/styles.module.scss';

FormikSelect.propTypes = {
  label: PropTypes.string.isRequired,
  formikName: PropTypes.string.isRequired,
  formikBag: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
  required: PropTypes.bool,
  hintText: PropTypes.string,
  className: PropTypes.string,
};

export default function FormikSelect(props) {
  const {
    label,
    options,
    formikName,
    formikBag,
    required,
    hintText,
    className,
    ...otherProps
  } = props;
  const { values, touched, errors } = formikBag;
  const hasError = touched[formikName] && errors[formikName];
  const rawValue = values[formikName];
  const [labelText, setLabelText] = useState(label);

  useEffect(() => {
    setLabelText(`${label}${required ? '*' : ' '}`);
  }, [required, label, labelText]);

  return (
    <Select
      key={labelText}
      label={labelText}
      name={formikName}
      outlined
      value={rawValue ? rawValue.toString() : ''}
      options={options}
      onChange={event => {
        formikBag.setFieldTouched(formikName, true);
        formikBag.setFieldValue(formikName, event.currentTarget.value);
      }}
      className={classnames(sharedCss.wide, className)}
      invalid={hasError}
      helpText={
        hasError
          ? {
              persistent: true,
              validationMsg: true,
              children: (
                <span>{errors[formikName].message || errors[formikName]}</span>
              ),
            }
          : hintText
          ? {
              persistent: true,
              validationMsg: false,
              children: <span>{hintText}</span>,
            }
          : null
      }
      {...otherProps}
    />
  );
}
