import React from 'react';
import { render } from 'react-dom';
import { createStore } from './reduxBootstrap';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import config from './helpers/config';

import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import userReducer from 'store/slices/userSlice';
import settingsReducer from 'store/slices/settingsSlice';
import clientsReducer from 'store/slices/clientsSlice';
import usersReducer from 'store/slices/usersSlice';
import textCenterReducer from 'store/slices/textCenterSlice';
import tenantsReducer from 'store/slices/tenantsSlice';
import templatesReducer from 'store/slices/templatesSlice';

import { setToken } from 'store/slices/userSlice';

import userSaga from 'store/sagas/user.saga';
import settingsSaga from 'store/sagas/settings.saga';
import clientsSaga from 'store/sagas/clients.saga';
import usersSaga from 'store/sagas/users.saga';
import tenantsSaga from 'store/sagas/tenants.saga';
import templatesSaga from 'store/sagas/templates.saga';

import './styles/reset.scss';
import '@rmwc/button/styles';
import '@rmwc/card/styles';
import '@rmwc/checkbox/styles';
import '@rmwc/chip/styles';
import '@rmwc/circular-progress/styles';
import '@rmwc/data-table/styles';
import '@rmwc/dialog/styles';
import '@rmwc/elevation/styles';
import '@rmwc/fab/styles';
import '@rmwc/grid/styles';
import '@rmwc/icon/styles';
import '@rmwc/linear-progress/styles';
import '@rmwc/list/styles';
import '@rmwc/radio/styles';
import '@rmwc/select/styles';
import '@rmwc/snackbar/styles';
import '@rmwc/switch/styles';
import '@rmwc/tabs/styles';
import '@rmwc/textfield/styles';
import '@rmwc/typography/styles';
import '@rmwc/top-app-bar';
import './styles/global.scss';
import 'src/styles/shared-ui/src/styles/material.scss';

import './global.scss?globalModule';

const randomInt = Math.floor(Math.random() * (9999999 - 1 + 1)) + 1;
// eslint-disable-next-line
const tabID = sessionStorage.tabID
  ? sessionStorage.tabID
  : (sessionStorage.tabID = randomInt);

const store = createStore(
  {
    user: userReducer,
    settings: settingsReducer,
    clients: clientsReducer,
    users: usersReducer,
    textCenter: textCenterReducer,
    tenants: tenantsReducer,
    templates: templatesReducer,
  },
  [
    userSaga(),
    settingsSaga(),
    clientsSaga(),
    usersSaga(),
    tenantsSaga(),
    templatesSaga(),
  ]
);

const token = localStorage.getItem('token');
if (token) {
  const user = JSON.parse(localStorage.getItem('user'));
  const textCenterToken = localStorage.getItem('textCenterToken');
  store.dispatch(
    setToken({
      user: user,
      token: token,
      textCenterToken: textCenterToken,
      response: {},
    })
  );
}

render(
  <Provider store={store}>
    <ErrorBoundary>
      <Router basename={config.publicPath}>
        <App />
      </Router>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('component-root')
);
