import React from 'react';
import { Grid, GridCell } from '@rmwc/grid';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { saveTemplate } from 'store/slices/templatesSlice';
import classnames from 'classnames';
import css from './templateList.module.scss';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import { Button } from '@rmwc/button';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import FormikTextField from 'src/components/FormikTextField';
import * as Yup from 'yup';

Template.propTypes = {
  template: PropTypes.object,
  setTemplate: PropTypes.func,
};

export default function Template({ template, setTemplate }) {
  const dispatch = useDispatch();

  const formikBag = useFormik({
    initialValues: template
      ? template
      : {
          name: '',
          description: '',
          message: '',
        },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please include a name'),
      description: Yup.string().required('Please include a description'),
      message: Yup.string().required('Please include an message'),
    }),
    onSubmit: valuesForSave => {
      dispatch(
        saveTemplate({
          dispatch,
          template: valuesForSave,
        })
      );
    },
  });

  const { handleSubmit } = formikBag;

  return (
    <>
      <Grid style={{ padding: '0px' }}>
        <GridCell span={4}>
          <Typography use="headline6" tag="h2">
            {template ? 'Edit Template' : 'New Template'}
          </Typography>
        </GridCell>
        <GridCell span={8}>
          <Button
            outlined
            raised
            className={classnames(css.floatRight)}
            theme={['secondaryBg', 'onSecondary']}
            onClick={() => {
              setTemplate(undefined);
            }}
            label="New Template"
          />
        </GridCell>
      </Grid>
      <hr />
      <Grid>
        <GridCell span={4}>
          <FormikTextField
            label="Name"
            formikName="name"
            formikBag={formikBag}
            required
            autoComplete="off"
            className={sharedCss.marginTop}
          />
        </GridCell>
        <GridCell span={4}>
          <FormikTextField
            label="Description"
            formikName="description"
            formikBag={formikBag}
            required
            autoComplete="off"
            className={sharedCss.marginTop}
          />
        </GridCell>
        <GridCell span={12}>
          <FormikTextField
            label="Message"
            formikName="message"
            formikBag={formikBag}
            textarea
            rows={5}
            required
            autoComplete="off"
            className={sharedCss.marginTop}
            fullwidth
          />
        </GridCell>
      </Grid>
      <hr className={sharedCss.marginTop} />
      <Button
        outlined
        theme={['secondaryBg', 'onSecondary']}
        label="Cancel"
        onClick={() => {
          setTemplate(undefined);
        }}
      />
      <Button
        outlined
        raised
        className={classnames(css.floatRight)}
        theme={['secondaryBg', 'onSecondary']}
        onClick={() => {
          handleSubmit();
        }}
        label={template ? 'Submit' : 'Create'}
      />
    </>
  );
}
