import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from '@rmwc/dialog';
import { useFormik } from 'formik';
import { deleteUser } from 'store/slices/userSlice';
import css from './userList.module.scss';

DeleteUserDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default function DeleteUserDialog({ user, open, setOpen, onClose }) {
  const dispatch = useDispatch();

  const { handleSubmit } = useFormik({
    initialValues: user,
    enableReinitialize: true,
    onSubmit: valuesForSave => {
      dispatch(deleteUser({ dispatch, user: valuesForSave }));
      setOpen(false);
    },
  });

  if (!open) {
    return null;
  }

  return (
    <Dialog open={true} className="deleteUserDialog">
      <DialogTitle>Remove User?</DialogTitle>
      <DialogContent className={css.userDialog}>
        <hr />
        <div>
          Are you sure that you want to remove this user?
        </div>
        <hr />
      </DialogContent>
      <DialogActions>
        <DialogButton
          style={{ float: 'left' }}
          onClick={onClose}
          action="close"
        >
          Cancel
        </DialogButton>
        <DialogButton action="Submit" onClick={handleSubmit} raised>
          Remove
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
