import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    hasError: false,
    errorMessage: undefined,
    user: undefined,
    allColumnsSearchQuery: undefined,
    users: [],
  },
  reducers: {
    getUsers() {},
    setUsers(state, action) {
      state.users = action.payload.users;
    },
    clearUsers(state) {
      state.users = [];
    },
    deleteUser() {},
  },
});

export const { getUsers, setUsers, clearUsers, deleteUser } = usersSlice.actions;

export default usersSlice.reducer;
