import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from '@rmwc/dialog';
import { Grid, GridCell } from '@rmwc/grid';
import { saveAvatar } from '../../store/slices/userSlice';

EditAvatarDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  client: PropTypes.object,
};

export default function EditAvatarDialog({ open, onClose }) {
  const dispatch = useDispatch();
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, height: 30 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop2 = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop2.width * pixelRatio;
    canvas.height = crop2.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop2.x * scaleX,
      crop2.y * scaleY,
      crop2.width * scaleX,
      crop2.height * scaleY,
      0,
      0,
      crop2.width,
      crop2.height
    );
  }, [completedCrop]);

  if (!open) {
    return null;
  }

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function getCroppedImg(image, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  }

  function submitNewAvatar() {
    getCroppedImg(imgRef.current, 'newAvatar.jpeg').then(function(blob) {
      dispatch(saveAvatar({ avatar: blob }));
      onClose();
    });
  }

  //might need class on DialogContent
  return (
    <Dialog open={true} className="editAvatarDialog">
      <DialogTitle>Edit Avatar</DialogTitle>
      <DialogContent>
        <hr />
        <Grid>
          <GridCell span={12}>
            <div>
              <input type="file" accept="image/*" onChange={onSelectFile} />
            </div>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={c => setCompletedCrop(c)}
            />
          </GridCell>
        </Grid>
        <hr />
      </DialogContent>
      <DialogActions>
        <DialogButton
          style={{ float: 'left' }}
          onClick={onClose}
          action="close"
        >
          Cancel
        </DialogButton>
        <DialogButton
          onClick={() => {
            submitNewAvatar();
          }}
          raised
        >
          Submit
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
