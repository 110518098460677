import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from '@rmwc/dialog';
import { useFormik } from 'formik';
import { registerUser } from 'store/slices/userSlice';
import { Grid, GridCell } from '@rmwc/grid';
import classnames from 'classnames';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import css from './userList.module.scss';
import FormikSelect from 'src/components/FormikSelect';
import FormikTextField from '../../components/FormikTextField';
import * as Yup from 'yup';
import {
  permissionLevelsOptions2,
  permissionLevelsOptions1,
  permissionLevels,
} from 'src/enums/roleEnums';

UserDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default function UserDialog({ user = undefined, open, onClose }) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.user.user);

  const formikBag = useFormik({
    initialValues: user
      ? user
      : {
          name: '',
          username: '',
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          phone: '',
          permissionLevel: '0',
        },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      username: Yup.string().required('Please include a username'),
      password: user
        ? undefined
        : Yup.string().required('Please include a password'),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
      phone: Yup.string().required('Please include a phone'),
      firstName: Yup.string().required('Please include a first name'),
      lastName: Yup.string().required('Please include a last name'),
      email: Yup.string().required('Please include an email'),
    }),
    onSubmit: valuesForSave => {
      dispatch(
        registerUser({
          dispatch,
          registration: valuesForSave,
          callGetUsers: true,
        })
      );
    },
  });

  const { handleSubmit, values } = formikBag;
  if (!open) {
    return null;
  }

  return (
    <Dialog open={true} className="userDialog">
      <DialogTitle>{user ? 'Edit User' : 'New User'}</DialogTitle>
      <DialogContent className={css.userDialog}>
        <hr />
        <Grid>
          <GridCell span={4}>
            <FormikTextField
              label="Username"
              formikName="username"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
          </GridCell>
          <GridCell span={4}>
            <FormikTextField
              label="First Name"
              formikName="firstName"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
          </GridCell>
          <GridCell span={4}>
            <FormikTextField
              label="Last Name"
              formikName="lastName"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
          </GridCell>
          <GridCell span={6}>
            <FormikTextField
              label="Email"
              formikName="email"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
          </GridCell>
          <GridCell span={6}>
            <FormikTextField
              label="Sending Phone Number"
              formikName="phone"
              formikBag={formikBag}
              required
              autoComplete="off"
              className={sharedCss.marginTop}
            />
          </GridCell>
          <GridCell span={6}>
            <FormikTextField
              label="Password"
              formikName="password"
              formikBag={formikBag}
              type="password"
              required={user === undefined}
              autoComplete="off"
              className={sharedCss.marginTop}
            />
          </GridCell>
          <GridCell span={6}>
            <FormikTextField
              label="Confirm Password"
              formikName="confirmPassword"
              formikBag={formikBag}
              type="password"
              required={values?.password?.length || !user}
              autoComplete="off"
              className={sharedCss.marginTop}
            />
          </GridCell>
          <GridCell span={6}>
            {values && (
              <FormikSelect
                label="Permission Level"
                formikName="permissionLevel"
                formikBag={formikBag}
                default
                options={
                  loggedInUser.permissionLevel.toString() ===
                  permissionLevels.superAdmin
                    ? permissionLevelsOptions2
                    : permissionLevelsOptions1
                }
                className={classnames(sharedCss.wide, sharedCss.marginTop)}
                required
              />
            )}
          </GridCell>
        </Grid>
        <hr />
      </DialogContent>
      <DialogActions>
        <DialogButton
          style={{ float: 'left' }}
          onClick={onClose}
          action="close"
        >
          Cancel
        </DialogButton>
        <DialogButton action="Submit" onClick={handleSubmit} raised>
          Submit
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
