import { all, put, takeLatest } from 'redux-saga/effects';
import * as api from 'src/api';
import snackbarQueue from 'helpers/snackbarQueue';
import { callWithToken } from 'helpers/sagaHelpers';
import {
  saveSettings,
  getSettings,
  setSettings,
  getPublicRegistration,
  setPublicRegistration,
} from 'store/slices/settingsSlice';
import { transformSetting } from 'helpers/settingsTransform';
import { setTextCenterToken } from '../slices/userSlice';

function* getSettingsSaga() {
  try {
    const settings = yield callWithToken(api.getSettings);
    yield put(setSettings({ settings: transformSetting(settings) }));
  } catch (e) {
    snackbarQueue.notify({
      body: 'There was an error getting your settings...',
      dismissesOnAction: true,
    });
  }
}

function* getPublicRegistrationSaga() {
  try {
    const publicRegistration = yield callWithToken(api.getPublicRegistration);
    yield put(
      setPublicRegistration({
        publicRegistration: publicRegistration.publicRegistration,
      })
    );
  } catch (e) {
    snackbarQueue.notify({
      body: 'There was an error getting your settings...',
      dismissesOnAction: true,
    });
  }
}

function* saveSettingsSaga(action) {
  const { settings } = action.payload;

  try {
    const newSettings = yield callWithToken(api.setSettings, settings);
    yield put(setSettings({ settings: newSettings }));
    yield put(
      setTextCenterToken({ textCenterToken: newSettings.textCenterToken })
    );

    snackbarQueue.notify({
      body: 'Settings have successfully been saved...',
      dismissesOnAction: true,
    });
  } catch (e) {
    snackbarQueue.notify({
      body: 'There was an error saving your settings...',
      dismissesOnAction: true,
    });
  }
}

export default function*() {
  yield all([
    yield takeLatest(getSettings.type, getSettingsSaga),
    yield takeLatest(saveSettings.type, saveSettingsSaga),
    yield takeLatest(getPublicRegistration.type, getPublicRegistrationSaga),
  ]);
}
