import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from '@rmwc/dialog';
import { useFormik } from 'formik';
import { Grid, GridCell } from '@rmwc/grid';
import { TextField } from '@rmwc/textfield';
import classnames from 'classnames';
import sharedCss from 'src/styles/shared-ui/styles.module.scss';
import css from 'src/components/supportmodal.module.scss';
import { submitSupportTicket } from 'src/store/slices/userSlice';

SupportModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function SupportModal({ open, setOpen }) {
  const dispatch = useDispatch();

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      subject: '',
      body: '',
    },
    enableReinitialize: true,
    onSubmit: valuesForSave => {
      dispatch(submitSupportTicket({ dispatch, supportTicket: valuesForSave }));
      setFieldValue('subject', '');
      setFieldValue('body', '');
      setOpen(false);
    },
  });

  if (!open) {
    return null;
  }

  return (
    <Dialog open={true} className="supportDialog">
      <DialogTitle>Open a Support Ticket</DialogTitle>
      <DialogContent className={css.supportDialog}>
        <hr />
        <Grid>
          <GridCell span={12}>
            <TextField
              outlined
              label="Subject"
              name="subject"
              value={values.subject}
              onChange={evt => {
                setFieldValue('subject', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
          <GridCell span={12}>
            <TextField
              outlined
              textarea
              outlined
              fullwidth
              rows={8}
              label="Description"
              name="body"
              value={values.body}
              onChange={evt => {
                setFieldValue('body', evt.target.value);
              }}
              className={classnames(sharedCss.wide, sharedCss.marginTop)}
            />
          </GridCell>
        </Grid>
        <hr />
      </DialogContent>
      <DialogActions>
        <DialogButton
          style={{ float: 'left' }}
          onClick={() => {
            setOpen(false);
          }}
          action="close"
        >
          Cancel
        </DialogButton>
        <DialogButton action="Submit" onClick={handleSubmit} raised>
          Submit
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
