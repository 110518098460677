import { createSlice } from '@reduxjs/toolkit';

const tenantsSlice = createSlice({
  name: 'tenants',
  initialState: {},
  reducers: {
    saveTenant() {},
  },
});

export const { saveTenant } = tenantsSlice.actions;

export default tenantsSlice.reducer;
