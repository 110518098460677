import { createSlice } from '@reduxjs/toolkit';

const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    hasError: false,
    errorMessage: undefined,
    client: undefined,
    allColumnsSearchQuery: undefined,
    clients: [],
    clientToken: undefined,
  },
  reducers: {
    saveClient() {},
    deleteClient() {},
    getClients() {},
    setClients(state, action) {
      state.clients = action.payload.clients.clients;
    },
    clearClients(state) {
      state.clients = [];
    },
    setSliceClient(state, action) {
      state.client = action.payload.client;
      state.clientToken = undefined;
    },
    setSliceClientToken(state, action) {
      state.clientToken = action.payload.clientToken;
    },
    searchAllClientColumns(state, action) {
      const allColumnsSearchQuery = action.payload.allColumnsSearchQuery;

      if (!allColumnsSearchQuery) {
        state.allColumnsSearchQuery = undefined;
      }

      state.allColumnsSearchQuery = allColumnsSearchQuery;
    },
  },
});

export const {
  getClients,
  saveClient,
  deleteClient,
  setSliceClient,
  clearClients,
  setClients,
  searchAllClientColumns,
  setSliceClientToken,
} = clientsSlice.actions;

export default clientsSlice.reducer;
